import { createTheme } from "@mui/material/styles"
import { Box } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import type {} from "@mui/lab/themeAugmentation"
import type {} from "@mui/x-date-pickers/themeAugmentation"
import { colors } from "./colors"
import { ExpandMore } from "@mui/icons-material"

declare module "@mui/material/styles" {
  interface TypographyVariants {
    extraSmall?: React.CSSProperties
    extraSmallMedium?: React.CSSProperties
    extraSmallSemiBold?: React.CSSProperties
    extraSmallBold?: React.CSSProperties
    small?: React.CSSProperties
    smallMedium?: React.CSSProperties
    smallSemiBold?: React.CSSProperties
    smallBold?: React.CSSProperties
    regular?: React.CSSProperties
    regularMedium?: React.CSSProperties
    regularSemiBold?: React.CSSProperties
    regularBold?: React.CSSProperties
    large?: React.CSSProperties
    largeMedium?: React.CSSProperties
    largeSemiBold?: React.CSSProperties
    largeBold?: React.CSSProperties
    extraLarge?: React.CSSProperties
    extraLargeMedium?: React.CSSProperties
    extraLargeSemiBold?: React.CSSProperties
    extraLargeBold?: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    extraSmall?: React.CSSProperties
    extraSmallMedium?: React.CSSProperties
    extraSmallSemiBold?: React.CSSProperties
    extraSmallBold?: React.CSSProperties
    small?: React.CSSProperties
    smallMedium?: React.CSSProperties
    smallSemiBold?: React.CSSProperties
    smallBold?: React.CSSProperties
    regular?: React.CSSProperties
    regularMedium?: React.CSSProperties
    regularSemiBold?: React.CSSProperties
    regularBold?: React.CSSProperties
    large?: React.CSSProperties
    largeMedium?: React.CSSProperties
    largeSemiBold?: React.CSSProperties
    largeBold?: React.CSSProperties
    extraLarge?: React.CSSProperties
    extraLargeMedium?: React.CSSProperties
    extraLargeSemiBold?: React.CSSProperties
    extraLargeBold?: React.CSSProperties
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    extraSmall: true
    extraSmallMedium: true
    extraSmallSemiBold: true
    extraSmallBold: true
    small: true
    smallMedium: true
    smallSemiBold: true
    smallBold: true
    regular: true
    regularMedium: true
    regularSemiBold: true
    regularBold: true
    large: true
    largeMedium: true
    largeSemiBold: true
    largeBold: true
    extraLarge: true
    extraLargeMedium: true
    extraLargeSemiBold: true
    extraLargeBold: true
    subtitle1: false
    subtitle2: false
    body1: false
    body2: false
    caption: false
    button: false
    overline: false
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      light: colors.primary,
      dark: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
      light: colors.secondary,
      dark: colors.secondary,
      contrastText: colors.black,
    },
    success: {
      main: colors.green,
    },
  },
  typography: {
    fontFamily: ["Mulish", "sans-serif"].join(","),
    h1: {
      fontSize: "66px",
      fontWeight: 500,
      lineHeight: "82px",
    },
    h2: {
      fontSize: "50px",
      fontWeight: 500,
      lineHeight: "64px",
    },
    h3: {
      fontSize: "36px",
      fontWeight: 600,
      lineHeight: "48px",
    },
    h4: {
      fontSize: "28px",
      fontWeight: 600,
      lineHeight: "38px",
    },
    h5: {
      fontSize: "21px",
      fontWeight: 600,
      lineHeight: "31px",
    },
    h6: {
      fontSize: "19px",
      fontWeight: 600,
    },
    extraSmall: {
      fontSize: "10px",
      fontWeight: 400,
      lineHeight: "15px",
    },
    extraSmallMedium: {
      fontSize: "10px",
      fontWeight: 500,
      lineHeight: "15px",
    },
    extraSmallSemiBold: {
      fontSize: "10px",
      fontWeight: 600,
      lineHeight: "15px",
    },
    extraSmallBold: {
      fontSize: "10px",
      fontWeight: 700,
      lineHeight: "15px",
    },
    small: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
    },
    smallMedium: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "18px",
    },
    smallSemiBold: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "18px",
    },
    smallBold: {
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
    },
    regular: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "21px",
    },
    regularMedium: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
    },
    regularSemiBold: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "21px",
    },
    regularBold: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "21px",
    },
    large: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    largeMedium: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
    },
    largeSemiBold: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
    },
    largeBold: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
    },
    extraLarge: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "27px",
    },
    extraLargeMedium: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "27px",
    },
    extraLargeSemiBold: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "27px",
    },
    extraLargeBold: {
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "27px",
    },
    // subtitle1: undefined,
    // subtitle2: undefined,
    // body1: undefined,
    // body2: undefined,
    // caption: undefined,
    // button: undefined,
    // overline: undefined,
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontFamily: ["Mulish", "sans-serif"].join(","),
          fontSize: "14px",
          fontWeight: "600",
          "&.MuiButton-outlined": {
            borderColor: colors.gray7,
            color: colors.black2,
            backgroundColor: colors.white,
            "&:hover": {
              backgroundColor: colors.gray6,
            },
            "&.Mui-disabled": {
              color: colors.gray2,
            },
          },
          "&.MuiButton-outlinedSizeMedium": {
            padding: "8px 24px",
            "&.MuiLoadingButton-root": {
              padding: "8px 44px",
            },
          },
          "&.MuiButton-containedSizeMedium": {
            padding: "10px 24px",
            "&.MuiLoadingButton-root": {
              padding: "10px 44px",
            },
          },
          "&.MuiButton-outlinedSizeSmall": {
            padding: "4px 24px",
            "&.MuiLoadingButton-root": {
              padding: "4px 44px",
            },
          },
          "&.MuiButton-containedSizeSmall": {
            padding: "6px 24px",
            "&.MuiLoadingButton-root": {
              padding: "6px 44px",
            },
          },
          "&.Mui-disabled": {
            backgroundColor: colors.secondary,
            borderColor: colors.secondary,
          },
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
        loadingPosition: "start",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "filled",
        size: "small",
        InputProps: {
          disableUnderline: true,
        },
        InputLabelProps: {
          sx: {
            color: colors.gray2,
          },
        },
      },
      styleOverrides: {
        root: ({ theme }: any) => {
          return {
            fontFamily: ["Mulish", "sans-serif"].join(","),
            borderRadius: "8px",
            "& .MuiFilledInput-root": {
              borderRadius: "8px",
              backgroundColor: "transparent",
              border: `1px solid ${colors.gray2}`,
              transition: theme.transitions.create([
                "border-color",
                "background-color",
              ]),
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&.Mui-focused": {
                backgroundColor: "transparent",
                borderColor: theme.palette.primary.main,
              },
              "&.Mui-error": {
                borderColor: theme.palette.error.main,
              },
              "&.Mui-disabled": {
                backgroundColor: colors.secondary,
              },
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: "500",
            },
            "& .MuiOutlinedInput-root": {
              backgroundColor: colors.white,
              borderRadius: 8,
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${colors.primary}`,
              },
              "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.error.main,
              },
              "&.Mui-disabled": {
                backgroundColor: colors.secondary,
              },
            },
          }
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          padding: "6px",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: (
          <Box
            width="17px"
            height="17px"
            border={`1px solid ${colors.gray2}`}
            borderRadius="3px"
          />
        ),
        checkedIcon: (
          <Box
            width="17px"
            height="17px"
            border={`1px solid ${colors.primary}`}
            borderRadius="3px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize="17px"
          >
            <CheckIcon fontSize="inherit" color="primary" />
          </Box>
        ),
      },
      styleOverrides: {
        root: {
          color: colors.gray2,
          padding: 0,
          backgroundColor: "transparent",
          borderRadius: "3px",
          "&.Mui-disabled": {
            backgroundColor: colors.secondary,
            "& .MuiSvgIcon-root": {
              color: colors.gray3,
            },
            "& .MuiBox-root": {
              borderColor: colors.gray3,
            },
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: "regular",
        color: colors.black,
      },
      styleOverrides: {
        root: {
          overflowWrap: "break-word",
          wordWrap: "break-word",
          wordBreak: "break-word",
          whiteSpace: "initial",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          width: "auto",
          backgroundColor: colors.white,
          borderTop: `1px solid ${colors.gray7}`,
          borderBottom: `1px solid ${colors.gray7}`,
          boxShadow: "none",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableHead-root": {
            backgroundColor: colors.gray10,
            "& .MuiTableCell-head": {
              fontFamily: ["Inter", "sans-serif"].join(","),
              fontSize: "12px",
              lineHeight: "normal",
              fontWeight: 500,
              padding: "8px",
              backgroundColor: "transparent",
              borderColor: colors.gray7,
            },
          },
          "& .MuiTableBody-root": {
            borderColor: colors.gray2,
            "& .MuiIconButton-root": {
              padding: "2px",
            },
            "& > .MuiTableRow-root": {
              height: "50px",
              "& .MuiTableCell-body": {
                borderBottom: `1px solid ${colors.gray2}`,
                fontFamily: ["Mulish", "sans-serif"].join(","),
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400,
                padding: "12px",
                color: colors.gray9,
              },
              // "&:last-child td, &:last-child th": {
              //   border: 0,
              // },
            },
          },
          "& .MuiTableCell-footer": {
            padding: "0px 8px",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          fontFamily: ["Mulish", "sans-serif"].join(","),
          "& .MuiMenuItem-root": {
            fontSize: 14,
            minHeight: "0px !important",
          },
        },
      },
    },
    MuiTablePagination: {
      defaultProps: {
        rowsPerPageOptions: [10, 20, 50, 100],
      },
      styleOverrides: {
        root: {
          fontFamily: ["Mulish", "sans-serif"].join(","),
          borderTop: 0,
          borderBottom: 0,
          backgroundColor: colors.white,
          "& .MuiTablePagination-actions": {
            marginRight: "8px",
          },
          "& .Mui-focused": {
            "& .MuiTablePagination-select": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiTablePagination-selectLabel": {
            fontSize: "12px",
            fontWeight: 400,
          },
          "& .MuiTablePagination-displayedRows": {
            fontSize: "12px",
            fontWeight: 400,
          },
          "& .MuiTablePagination-input": {
            fontSize: "12px",
            fontWeight: 400,
          },
          "& .MuiIconButton-root": {
            padding: "4px",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& .MuiSvgIcon-root": {
              color: colors.gray4,
            },
          },
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        slotProps: {
          textField: {
            variant: "filled",
            InputProps: { disableUnderline: true },
          },
        },
      },
    },
    MuiTimePicker: {
      defaultProps: {
        slotProps: {
          textField: {
            variant: "filled",
            InputProps: { disableUnderline: true },
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMore,
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          borderRadius: "8px",
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${colors.gray2}`,
            borderRadius: "8px",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${colors.gray2}`,
            borderRadius: "8px",
          },
          ":hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${colors.primary}`,
              borderRadius: "8px",
            },
          },
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: "regularBold",
      },
      styleOverrides: {
        root: {
          padding: "16px",
          backgroundColor: colors.secondary,
          marginBottom: "16px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }: any) => {
          return {
            padding: "16px",
            minWidth: "160px",
            [theme.breakpoints.up("sm")]: {
              minWidth: "360px",
            },
            [theme.breakpoints.up("md")]: {
              minWidth: "480px",
            },
          }
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "16px",
          paddingTop: "8px",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ theme }: any) => {
          return {
            fontFamily: ["Mulish", "sans-serif"].join(","),
            backgroundColor: "transparent",
            "& .MuiInputLabel-root": {
              color: colors.gray2,
              "&.Mui-focused": {
                color: colors.primary,
              },
            },
            "& .MuiInputLabel-outlined": {
              top: "-3px !important",
            },
            "& .MuiOutlinedInput-root": {
              height: "47.2px !important",
            },
            "& .MuiFilledInput-root": {
              maxHeight: 47.2,
              borderRadius: "8px",
              backgroundColor: "transparent",
              border: `1px solid ${colors.gray2}`,
              transition: theme.transitions.create([
                "border-color",
                "background-color",
              ]),
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&.Mui-focused": {
                "& .MuiSelect-select": {
                  backgroundColor: "transparent",
                },
                backgroundColor: "transparent",
                borderColor: theme.palette.primary.main,
              },
              "&.Mui-error": {
                borderColor: theme.palette.error.main,
              },
              "&.Mui-disabled": {
                backgroundColor: colors.secondary,
              },
            },
            "& .MuiInputBase-multiline": {
              maxHeight: "none !important",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: "500",
            },
          }
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: ["Mulish", "sans-serif"].join(","),
          fontSize: "12px",
          fontWeight: 400,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.white}`,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTab-root": {
            textTransform: "none",
          },
        },
      },
    },
  },
})
