import {
  memo,
  type SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react"
import {
  type Control,
  Controller,
  type FieldValues,
  type RegisterOptions,
  type UseFormSetValue,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  Autocomplete,
  TextField,
  debounce,
  type TextFieldProps,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { getPoliceDepartmentsAPI } from "../../../../../../services"
import { getNestedProperty } from "../../../../../../utils"

interface IProps {
  formControl: Control<FieldValues, any>
  field: IClaimTemplateMetadata
  disabled: boolean
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
  >
  onFieldBlur?: (field: IChangedField) => void
  onFieldFocus?: () => void
  setValue?: UseFormSetValue<FieldValues>
}

export const PoliceDepartmentController = memo((props: IProps) => {
  const {
    formControl,
    field,
    disabled,
    rules,
    onFieldBlur,
    onFieldFocus,
    setValue,
  } = props
  const { t } = useTranslation()
  const [search, setSearch] = useState("")

  const [isFocused, setFocused] = useState(false)

  const { data: policeDepartments, isLoading: arePoliceDepartmentsLoading } =
    useQuery({
      queryKey: [`${field.controlName}-police-departments`, search],
      queryFn: () => getPoliceDepartmentsAPI({ searchField: search }),
      enabled: isFocused,
    })

  const filteredPoliceDeparments = useMemo(
    () => policeDepartments?.filter((d) => !!d.department),
    [policeDepartments],
  )

  const onInputChange = useCallback(
    (_e: SyntheticEvent<Element, Event>, value: string) => {
      setSearch(value)
    },
    [],
  )

  const onInputChangedDelayed = debounce(onInputChange, 350)

  return (
    <Controller
      control={formControl}
      name={field.controlName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => {
        let formattedValue: any

        if (value) {
          formattedValue =
            filteredPoliceDeparments?.find((o) => o.department === value) ||
            value
        }

        return (
          <Autocomplete
            options={filteredPoliceDeparments ?? []}
            freeSolo
            disabled={disabled}
            filterOptions={(options) => options}
            value={formattedValue || ""}
            onInputChange={(event, nextValue) => {
              if (event?.type === "change") {
                onChange(!nextValue ? null : nextValue)
                onInputChangedDelayed(event, nextValue)
              }
            }}
            onFocus={() => {
              onFieldFocus?.()
              setFocused(true)
            }}
            onBlur={() => {
              onBlur()
              onFieldBlur?.({
                controlName: field.controlName,
                value,
              })
            }}
            onChange={(_e, data) => {
              onChange(data ? data.department : null)

              setSearch("")

              field?.autoPopulateFields?.forEach((f) => {
                const autoPopulateValue = getNestedProperty(data, f.property)
                setValue?.(f.controlName, autoPopulateValue)
              })
            }}
            loading={arePoliceDepartmentsLoading}
            loadingText={t("loading")}
            noOptionsText={t("noOptions")}
            isOptionEqualToValue={(option, value) =>
              option?.department === value?.department ||
              option?.department === value
            }
            getOptionLabel={(option) => option?.department ?? option}
            renderOption={(props, option) => (
              <li {...props}>
                {`${option.department ?? ""}${
                  option.nameAddition ? ` - ${option.nameAddition}` : ""
                }`}
                <br />
                {`${
                  option?.address?.postCodePlace
                    ? `${
                        option.address.postCodePlace.postCode
                          ? `${option.address.postCodePlace.postCode}, `
                          : ""
                      }${option.address.postCodePlace.place}`
                    : ""
                }${option.phoneNumber ? ` - ${option.phoneNumber}` : ""}`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...(params as TextFieldProps)}
                label={field.label}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                error={!!error?.message}
                helperText={error?.message}
              />
            )}
          />
        )
      }}
    />
  )
})
