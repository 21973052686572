import { styled } from "@mui/system"
import Table from "@mui/material/Table"
import { Autocomplete, TextField } from "@mui/material"
import { colors } from "../../../../utils"
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers"

interface IProps {
  isEditMode: boolean
}

export const CustomSubTable = styled(Table)<IProps>(({ isEditMode }) => ({
  "& .MuiTableHead-root": {
    position: "sticky",
    top: 0,
    zIndex: 0,
    backgroundColor: "transparent",
    "& .MuiTableRow-root": {
      height: "30px",
      "& .MuiTableCell-root": {
        padding: isEditMode ? "10px" : "15px 20px",
        fontWeight: 400,
        fontSize: "14px",
        backgroundColor: colors.secondary,
        borderBottom: `1px solid ${colors.gray2}`,
        "&:first-of-type": {
          backgroundColor: colors.secondary,
        },
        "&:last-of-type": {
          borderRight: "none",
          backgroundColor: isEditMode ? colors.white : "",
        },
      },
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      height: "30px",
      "& .MuiTableCell-root": {
        padding: isEditMode ? "10px" : "15px 20px",
        borderBottom: `1px solid ${colors.gray}`,
        verticalAlign: "middle",
        fontSize: "14px",
        fontWeight: 400,
        backgroundColor: colors.white,
        "&:first-of-type": {
          backgroundColor: colors.white,
        },
        "&:last-of-type": {
          borderRight: "none",
        },
      },
    },
  },
}))

export const CustomAutocomplete = styled(Autocomplete)(() => ({
  "& .MuiFilledInput-root": {
    paddingTop: 0,
  },
  "& .MuiInputBase-root": {
    height: "30px",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
  "& .MuiAutocomplete-endAdornment": {
    paddingTop: "3px",
  },
}))

export const CustomTextField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    height: "30px",
  },
  "& .MuiInputBase-input": {
    fontSize: "12px",
    paddingTop: "4px",
    textAlign: "right",
  },
}))

export const DatePicker = styled(MuiDatePicker)(() => ({
  "& .MuiInputBase-root": {
    height: "30px",
    width: "130px",
  },
  "& .MuiInputBase-input": {
    fontSize: "12px",
    paddingTop: "4px",
    textAlign: "right",
  },
}))
