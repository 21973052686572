import {
  useState,
  useRef,
  useCallback,
  useEffect,
  type MouseEventHandler,
} from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Typography,
  Button as MuiButton,
} from "@mui/material"
import { Button } from "./styled"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import CheckIcon from "@mui/icons-material/Check"
import { Controller, type FieldValues, useForm } from "react-hook-form"
import { colors, COST_STATUS_COLOR, COST_STATUSES } from "../../../../utils"

interface IProps {
  status: TCostPositionStatus
  disabled?: boolean
  onUpdateClick: (status: TCostPositionStatus) => void
}

interface IForm {
  status: TCostPositionStatus
}

export const UpdatePositionStatusPopper = (props: IProps) => {
  const { status, disabled, onUpdateClick } = props
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const { control, handleSubmit, reset } = useForm<IForm>({
    defaultValues: {
      status,
    },
  })

  useEffect(() => {
    if (status && open) {
      reset({
        status,
      })
    }
  }, [status, open])

  const handleToggle: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation()
      setOpen((prevOpen) => !prevOpen)
    },
    [],
  )

  const handleClose = useCallback((event: Event) => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }, [])

  const onUpdate = useCallback((data: FieldValues) => {
    onUpdateClick(data.status as TCostPositionStatus)
    setOpen(false)
  }, [])

  return (
    <>
      <ButtonGroup variant="text" ref={anchorRef}>
        <Button
          onClick={handleToggle}
          status={status}
          disabled={disabled}
          endIcon={
            disabled ? undefined : !open ? (
              <ExpandMoreIcon htmlColor={colors.gray9} />
            ) : (
              <ExpandLessIcon htmlColor={colors.gray9} />
            )
          }
        >
          <Box display="flex" alignItems="center" gap="4px">
            <Box
              width="10px"
              height="10px"
              borderRadius="5px"
              bgcolor={COST_STATUS_COLOR[status]}
            />
            <Typography>{t(status)}</Typography>
          </Box>
        </Button>
      </ButtonGroup>
      <Popper
        className="z-index-9999"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps} onClick={(e) => e.stopPropagation()}>
              <Paper>
                <Box display="flex" flexDirection="column" minWidth="150px">
                  <Box bgcolor={colors.secondary} padding="16px">
                    <Typography variant="smallBold">{t("status")}</Typography>
                  </Box>
                  <Box padding="16px">
                    <Controller
                      control={control}
                      name="status"
                      render={({ field: { value, onChange } }) => (
                        <>
                          {COST_STATUSES?.map((s) => (
                            <Box key={s} marginTop="8px">
                              <Button
                                fullWidth
                                status={s}
                                endIcon={
                                  value === s ? (
                                    <CheckIcon htmlColor={colors.black2} />
                                  ) : undefined
                                }
                                onClick={() => onChange(s)}
                              >
                                <Box
                                  width="10px"
                                  height="10px"
                                  borderRadius="5px"
                                  marginRight="5px"
                                  bgcolor={COST_STATUS_COLOR[s]}
                                />
                                <Typography>{t(s)}</Typography>
                              </Button>
                            </Box>
                          ))}
                        </>
                      )}
                    />
                    <Box marginTop="24px" />
                    <Box width="100%">
                      <MuiButton
                        variant="contained"
                        size="medium"
                        fullWidth
                        onClick={handleSubmit(onUpdate)}
                      >
                        {t("update")}
                      </MuiButton>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}
