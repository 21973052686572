import { InputAdornment, TextField, Typography } from "@mui/material"
import { Controller } from "react-hook-form"
import { type IFieldItemControllerProps } from "../../../../types"
import { EURO_SIGN } from "../../../../../../utils"
import { memo } from "react"

export const CurrencyController = memo((props: IFieldItemControllerProps) => {
  const {
    control,
    field,
    subsection,
    section,
    label,
    rules,
    isDisabled,
    onFieldFocus,
    onFieldBlur,
  } = props

  return (
    <Controller
      control={control}
      name={field.controlName}
      rules={rules}
      render={({ field: { value, onChange, onBlur } }) => (
        <TextField
          fullWidth
          value={value ?? ""}
          type="number"
          onFocus={() => {
            onFieldFocus?.(section.sectionType)
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="large">{EURO_SIGN}</Typography>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            const nextValue = e.target.value
            const nextValueParsed = Number(nextValue)
            if (
              nextValueParsed &&
              typeof nextValueParsed === "number" &&
              !isNaN(nextValueParsed)
            ) {
              onChange(nextValueParsed)
            } else {
              onChange(null)
            }
          }}
          onBlur={() => {
            onBlur()
            onFieldBlur?.(
              {
                controlName: field.controlName,
                value,
              },
              subsection,
              section,
            )
          }}
          label={label}
          disabled={isDisabled}
        />
      )}
    />
  )
})
