import { Controller } from "react-hook-form"
import { type IFieldItemControllerProps } from "../../../../types"
import { TimePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { memo } from "react"

export const TimeController = memo((props: IFieldItemControllerProps) => {
  const {
    control,
    field,
    subsection,
    section,
    label,
    rules,
    isDisabled,
    formMethods,
    onFieldFocus,
    onFieldBlur,
  } = props
  const { t } = useTranslation()
  const { clearErrors, setError } = formMethods

  return (
    <Controller
      control={control}
      name={field.controlName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <TimePicker
          className="width-100"
          label={label}
          ampm={false}
          timeSteps={{ minutes: 1 }}
          value={value ? dayjs(value) : null}
          onOpen={() => {
            onFieldFocus?.(section.sectionType)
          }}
          onChange={(value) => {
            const nextValue = value?.isValid()
              ? value.format("YYYY-MM-DDTHH:mm:00")
              : null
            onChange(nextValue)
            onFieldBlur?.(
              {
                controlName: field.controlName,
                value: nextValue,
              },
              subsection,
              section,
            )
          }}
          onClose={onBlur}
          disabled={isDisabled}
          slotProps={{
            textField: {
              error: !!error?.message,
              helperText: error?.message,
              onBlur,
            },
          }}
          onError={(e) => {
            if (!e) {
              clearErrors(field.controlName)
            } else if (e === "invalidDate") {
              setError(field.controlName, {
                message: t("invalidTimeFormatErrorMessage"),
              })
            }
          }}
        />
      )}
    />
  )
})
