import {
  Box,
  Checkbox,
  FormHelperText,
  Tooltip,
  Typography,
} from "@mui/material"
import { Controller } from "react-hook-form"
import { type IFieldItemControllerProps } from "../../../../types"
import { useTranslation } from "react-i18next"
import { colors } from "../../../../../../utils"
import { memo } from "react"

export const CheckboxController = memo((props: IFieldItemControllerProps) => {
  const {
    control,
    field,
    subsection,
    section,
    rules,
    isDisabled,
    formMethods,
    disabledField,
    onFieldFocus,
    onFieldBlur,
  } = props

  const { t } = useTranslation()

  const options = field?.selectionOptions?.options?.filter((o) => {
    if (o.conditionField) {
      const val = formMethods.watch(o.conditionField)
      return val === o.conditionValue
    }

    return true
  })

  return (
    <Controller
      control={control}
      name={field.controlName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <>
          <Box
            display="flex"
            flexDirection={options?.length === 1 ? "row" : "column"}
            alignItems={options?.length === 1 ? "center" : "flex-start"}
          >
            {(!!field.label || !!field.rules?.isRequired) &&
              !!options?.length &&
              options.length > 1 && (
                <Typography marginBottom="4px" variant="smallMedium">
                  {`${field.label || t("selectAnOptionPrompt")}${
                    field.rules?.isRequired ? "*" : ""
                  }`}
                </Typography>
              )}
            <Box display="flex" flexWrap="wrap" flexDirection="row" gap="16px">
              {options?.map((option, optionIndex) => {
                const isSingleChoice =
                  field.selectionOptions.numberOfAllowedSelections === 1

                const isChecked = isSingleChoice
                  ? value === option.value
                  : !!value?.includes(option.value)

                const handleChange = (nextValue: any) => {
                  onFieldFocus?.(section.sectionType)
                  if (isSingleChoice) {
                    const nextMappedValue =
                      nextValue === value ? null : nextValue

                    onChange(nextMappedValue)
                    onFieldBlur?.(
                      {
                        controlName: field.controlName,
                        value: nextMappedValue,
                      },
                      subsection,
                      section,
                    )
                  } else {
                    const valueArray = value ? value.split(",") : []

                    const index =
                      valueArray?.findIndex((e: any) => e === nextValue) ?? -1

                    let nextValueArray = valueArray

                    if (index !== -1) {
                      nextValueArray = [
                        ...valueArray.slice(0, index),
                        ...valueArray.slice(index + 1),
                      ]
                        .sort(undefined)
                        .join(",")
                    } else {
                      nextValueArray = [...valueArray, option.value]
                        .sort(undefined)
                        .join(",")
                    }

                    const nextMappedValue = nextValueArray || null

                    onChange(nextMappedValue)
                    onFieldBlur?.({
                      controlName: field.controlName,
                      value: nextMappedValue,
                    })
                  }

                  void formMethods.trigger(field.controlName)
                }

                const hasDisableCondition = !!option.disabledConditionField

                let isConditionallyDisabled = false

                if (hasDisableCondition) {
                  const disableConditionFieldValue = formMethods.watch(
                    option.disabledConditionField,
                  )

                  if (
                    disableConditionFieldValue === option.disabledConditionValue
                  ) {
                    isConditionallyDisabled = true
                  }
                }

                return (
                  <Box
                    key={`${field.id}-${optionIndex}`}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Tooltip
                      title={
                        disabledField ||
                        (isConditionallyDisabled
                          ? option.disabledConditionMessage
                          : undefined)
                      }
                    >
                      <span>
                        <Checkbox
                          checked={isChecked}
                          onChange={() => handleChange(option.value)}
                          disabled={isDisabled || isConditionallyDisabled}
                          onBlur={onBlur}
                        />
                      </span>
                    </Tooltip>
                    <Typography marginLeft="8px" color={colors.black}>
                      {option.label}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
            {options?.length === 1 && (
              <Typography marginLeft="8px" variant="small">
                {field.label}
              </Typography>
            )}
          </Box>
          {!!error?.message && (
            <FormHelperText className="Mui-error">
              {error.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  )
})
