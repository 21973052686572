import {
  Box,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  IconButton,
} from "@mui/material"
import { colors } from "../../../../../../utils"

export const EntriesWrapper = styled(Box)`
  display: flex;
  width: 100%;
  overflow: auto;
  gap: 12px;
  padding-bottom: 12px;
  padding-top: 8px;
`

export const EntryCard = styled(Box)`
  min-width: 400px;
  width: 400px;
  height: 360px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  padding: 16px 12px;
  background-color: ${colors.gray8};
  border: 1px solid ${colors.gray5};
  border-radius: 12px;
  overflow: auto;
  overscroll-behavior: contain;
`

interface IAccordionProps {
  expanded?: boolean
  tableName: string
  currentExpanded?: string
  rowsAdded?: Record<string, any[]>
}

export const StyledAccordion = styled(Accordion)<IAccordionProps>`
  width: 100%;
  box-shadow: none;
  border: 1.5px solid
    ${(props) =>
      props.currentExpanded === props.tableName
        ? colors.primary
        : colors.gray4};
  min-height: 50px;
  border-radius: 10px !important;
`

export const StyledAccordionSummary = styled(AccordionSummary)<IAccordionProps>`
  overflow: auto;
  height: 50px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px !important;
  border-bottom-left-radius: ${(props) =>
    props.currentExpanded === props.tableName ? "0px !important" : ""};
  border-bottom-right-radius: ${(props) =>
    props.currentExpanded === props.tableName ? "0px !important" : ""};
  align-items: center;
  background-color: ${(props) =>
    props?.rowsAdded?.[props.tableName]?.length !== 0 &&
    props?.rowsAdded?.[props.tableName] !== undefined
      ? colors.blue6
      : props.currentExpanded === props.tableName
      ? colors.gray6
      : colors.white};
`

export const StyledAccordionDetails = styled(AccordionDetails)<IAccordionProps>`
  position: relative;
  max-height: 460px;
  overflow-y: ${(props) =>
    props.rowsAdded![props.tableName]?.length > 3 ? "auto" : "hidden"};
  overflow-x: "hidden";
`

export const AddEntryButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 8px ${colors.gray2};
  }
`

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  mb: 1.5;
`

export const FieldsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  mb: 1.5;
`

export const TypographyOne = styled(Typography)`
  font-size: 17.5;
  mb: 1;
  font-weight: 700;
`

export const TypographyTwo = styled(Typography)`
  fontsize: 15;
  color: ${colors.gray3};
  mb: 1;
`

export const CustomizedTextField = styled(TextField)`
  border-bottom: none;
  overflow: auto;
  height: 35px;
  background-color: white;

  & .MuiInputBase-root {
    height: 35px;
    padding: 8px; /* Adjust padding to match the theme spacing or your needs */
  }
`

export const DeleteIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
`
