import { TableCell, TableRow, TableSortLabel } from "@mui/material"
import { useTranslation } from "react-i18next"

interface IProps {
  orderBy: string
  order: TOrder
  onSortClick: (columnName: string) => void
}

export const GroupHeadRow = (props: IProps) => {
  const { orderBy, order, onSortClick } = props
  const { t } = useTranslation()

  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell width="60%">
        <TableSortLabel
          active={orderBy === "name"}
          direction={order}
          onClick={() => onSortClick("name")}
        >
          {t("groupName")}
        </TableSortLabel>
      </TableCell>
      <TableCell width="40%">
        <TableSortLabel
          active={orderBy === "createdDate"}
          direction={order}
          onClick={() => onSortClick("createdDate")}
        >
          {t("createdDate")}
        </TableSortLabel>
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}
