import {
  Autocomplete,
  Dialog,
  DialogContent,
  styled,
  TextField,
  Tab,
  Box,
  Button,
} from "@mui/material"
import { colors } from "../../../../../../utils"
import { IStyledProps } from "@cyntler/react-doc-viewer"

export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 100%;
    max-width: 100%;
    height: 90%;
    max-height: 90%;

    @media (max-width: 600px) {
      max-height: 100%;
    }
  }
`

export const CustomTab = styled(Tab)`
  font-size: 14px;
`
export const CustomDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-height: 100%;
  padding: 12px;
`

export const FullHeightAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    max-height: 50px;
  }

  .MuiInputBase-root {
    max-height: 50px;
    padding: 0;
  }

  .MuiOutlinedInput-input {
    padding: 10px;
  }
`

export const CustomTextField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    height: "30px",
  },
  "& .MuiFilledInput-input": {
    paddingTop: "4px",
    fontSize: "16px",
  },
}))

export const UploadBox = styled(Box)`
  padding: 0;
  border-radius: 8px;
  text-transform: none;
  transition:
    transform 0.2s,
    background-color 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 140px;
  border: 2px dashed ${colors.primary};
  ml: 10px;
  mr: 10px;
`

export const ControllerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(3.5),
  justifyContent: "start",
  padding: theme.spacing(2),
}))

interface IStylingProps {
  key?: number
  templateName: string
  name: string
}
export const ControllerBoxWrapper = styled(Box)<IStylingProps>`
  border: ${(props) =>
    props.templateName === props.name
      ? `1.5px solid ${colors.primary}`
      : "none"};
  border-radius: 8px;
`

export const TemplateSelectButton = styled(Button)<IStylingProps>`
  padding: 0;
  border-radius: 8px;
  text-transform: none;
  background-color: ${(props) =>
    props.templateName.includes(props.name) ? colors.blue6 : "white"};
  transition:
    transform 0.2s,
    background-color 0.2s;

  &:hover {
    transform: scale(1.009);
    background-color: ${(props) =>
      props.templateName.includes(props.name) ? colors.blue7 : "#f0f0f0"};
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  width: 180px;
  height: 200px;
  border-bottom-left-radius: ${(props) =>
    props.templateName === props.name ? 0 : "8px"};
  border-bottom-right-radius: ${(props) =>
    props.templateName === props.name ? 0 : "8px"};
  box-shadow: none;
`

interface IEditButtonProps extends IStylingProps {
  disabled: boolean
  size: string
  onClick: () => any
}

export const EditButton = styled(Button)<IStylingProps>`
  background-color: ${colors.blue6};
  border-radius: 8px;
  color: black;
  box-shadow: none;
  border-top-left-radius: ${(props) =>
    props.templateName === props.name ? 0 : "8px"};
  border-top-right-radius: ${(props) =>
    props.templateName === props.name ? 0 : "8px"};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  width: 180px;
`
