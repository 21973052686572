import { styled } from "@mui/system"
import Table from "@mui/material/Table"
import { colors } from "../../../../../utils"

interface IProps {
  isEditMode: boolean
}

export const CustomSubTableDetails = styled(Table)<IProps>(
  ({ isEditMode }) => ({
    "& .MuiTableHead-root": {
      position: "sticky",
      top: 0,
      zIndex: 0,
      backgroundColor: "transparent",
      "& .MuiTableRow-root": {
        height: "30px",
        "& .MuiTableCell-root": {
          padding: isEditMode ? "10px" : "15px 20px",
          fontWeight: 400,
          fontSize: "14px",
          backgroundColor: colors.secondary,
          borderBottom: `1px solid ${colors.gray2}`,
          "&:first-of-type": {
            backgroundColor: colors.secondary,
          },
          "&:last-of-type": {
            borderRight: "none",
            backgroundColor: isEditMode ? colors.white : "",
          },
        },
      },
    },
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        height: "30px",
        "& .MuiTableCell-root": {
          padding: isEditMode ? "10px" : "15px 20px",
          borderBottom: "none",
          verticalAlign: "middle",
          fontSize: "14px",
          fontWeight: 400,

          "&:last-of-type": {
            borderRight: "none",
            BorderBottom: "none",
            backgroundColor: isEditMode ? colors.white : "",
          },
        },
      },
    },
  }),
)
