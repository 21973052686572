import { Box, styled } from "@mui/material"
import { colors } from "../../../../utils"

interface IProps {
  disabled?: boolean
  error?: boolean
}

export const ContainerBox = styled(Box)<IProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: 1px dashed
    ${(props) =>
      props.disabled
        ? colors.gray2
        : props.error
        ? colors.red
        : colors.primary};
  background-color: ${(props) =>
    props.disabled
      ? colors.secondary
      : props.error
      ? colors.red4
      : colors.transparent};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: pointer;
  padding: 16px;
  &:hover {
    background-color: ${colors.blue6};
  }
`
