import {
  Box,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useAppContext } from "../../../../contexts"
import { getOpenActivitiesAPI } from "../../../../services"
import { useQuery } from "@tanstack/react-query"
import type { IFilters } from "../../DashboardPage"
import { UpdateStatusPopper } from "../../../../components/UpdateStatusPopper/UpdateStatusPopper"
import { Link } from "react-router-dom"
import { FindInPageOutlined } from "@mui/icons-material"
import { CustomPagination } from "../CustomPagination"
import { TableCellNoPadding } from "../../styled"
import dayjs from "dayjs"

export const OpenActivities = ({
  filters,
  selectedGroups,
}: {
  filters: IFilters
  selectedGroups: string[]
}) => {
  const { t } = useTranslation()
  const { state: appState } = useAppContext()
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [sortBy, setSortBy] = useState("ClaimId")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc")

  const groupIds = selectedGroups || appState?.groupId!

  const {
    data: openActivities,
    isRefetching,
    isLoading,
  } = useQuery<IDashboardTable>({
    queryKey: [
      "openActivities",
      filters,
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
      groupIds,
    ],
    queryFn: () =>
      getOpenActivitiesAPI({
        groupIds,
        from: filters.from,
        to: filters.to,
        pageNumber: pageNumber + 1,
        pageSize,
        sortBy,
        sortOrder,
      }),
    enabled:
      filters.to && filters.from
        ? dayjs(filters.to).isAfter(filters.from)
        : true,
    refetchOnMount: true,
  })

  const handleChangePage = (_: unknown, newPage: number) => {
    setPageNumber(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleSort = (column: string) => {
    setSortBy(column)
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
  }

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        flexDirection="row"
        gap="8px"
        marginBottom="8px"
      >
        <Typography
          variant="largeMedium"
          className="normal-word-break"
          margin="8px 0"
        >
          {t("openActivities")}
        </Typography>
      </Box>
      <Box display="grid">
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "ClaimId"}
                    direction={sortOrder}
                    onClick={() => handleSort("ClaimId")}
                  >
                    {t("claimID")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "DamagedPersonName"}
                    direction={sortOrder}
                    onClick={() => handleSort("DamagedPersonName")}
                  >
                    {t("damagedPersonName")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "Status"}
                    direction={sortOrder}
                    onClick={() => handleSort("Status")}
                  >
                    {t("status")}
                  </TableSortLabel>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isRefetching || isLoading ? (
                <>
                  {[...Array(pageSize)].map((_, index) => (
                    <TableRow key={index}>
                      {[...Array(4)].map((_, cellIndex) => (
                        <TableCell key={cellIndex}>
                          {cellIndex >= 2 ? (
                            <Skeleton variant="rounded" />
                          ) : (
                            <Skeleton />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                openActivities?.claims?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.advoWareId}</TableCell>
                    <TableCell>{row.damagedPersonName}</TableCell>
                    <TableCellNoPadding>
                      <Box display="flex">
                        <UpdateStatusPopper claim={row as any} />
                      </Box>
                    </TableCellNoPadding>
                    <TableCell>
                      <Link to={`/claims/${row.claimId}`}>
                        <IconButton>
                          <FindInPageOutlined fontSize="small" />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <CustomPagination
                  count={openActivities?.totalCount!}
                  page={pageNumber}
                  rowsPerPage={pageSize}
                  isDisabled={isRefetching || isLoading}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
