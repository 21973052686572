import { Box, styled } from "@mui/material"

export const SelectionWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: "center";
`
export const SelectionBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  border-bottom: none;
`

export const styledButton = {
  width: "175px",
  mr: 1,
  paddingLeft: "10px !important",
  display: "flex",
  justifyContent: "flex-start",
  mb: 2,
}

export const styledAccordionWrapper = {
  width: "100%",
  display: "flex",
  mt: 2,
  alignItems: "center",
}

export const styledRemoveButton = {
  width: "2.5%",
  height: "40px",
}
