import { useCallback, useEffect, useState } from "react"
import { Box, TextField, Typography } from "@mui/material"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import {
  updateUserAPI,
  updateUserProfilePictureAPI,
  deleteUserPermanentlyAPI,
} from "../../services"
import { EMAIL_REGEX_VALIDATION } from "../../utils"
import { useToast, useAppContext } from "../../contexts"
import { Avatar, EditButton } from "./styled"
import EditIcon from "@mui/icons-material/Edit"
import { UpdateProfilePictureDialog } from "./components"
import { AlertDialog, PhoneNumberInput } from "../../components"
import { isValidPhoneNumber } from "react-phone-number-input"

type TForm = Partial<Omit<IUser, "profilePicture">> & {
  profilePicture: IFile | undefined
}

export const MyProfilePage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()
  const { logOut } = useAppContext()
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, dirtyFields },
  } = useForm<TForm>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      profilePicture: {
        src: "",
        file: undefined,
      },
    },
  })

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const [isUpdateProfilePictureVisible, setUpdateProfilePictureVisible] =
    useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const { mutateAsync: updateUser, isPending: isUserUpdating } = useMutation({
    mutationFn: (nextUser: Partial<IUser>) => updateUserAPI(nextUser),
    onSuccess: (user: IUser) => {
      queryClient.setQueryData(["user"], user)
    },
  })

  const {
    mutateAsync: updateUserProfilePicture,
    isPending: isUserProfilePictureUpdating,
  } = useMutation({
    mutationFn: (profilePicture: FormData) =>
      updateUserProfilePictureAPI(profilePicture),
    onSuccess: (user: IUser) => {
      queryClient.setQueryData(["user"], user)
    },
  })

  const { mutate: deleteUserPermanently, isPending: isDeletingUser } =
    useMutation({
      mutationFn: () => deleteUserPermanentlyAPI(),
      onSuccess: async () => {
        toast.show(t("userDeleted"), "success")
        logOut()
      },
    })

  useEffect(() => {
    if (user) {
      reset({
        firstName: user.firstName ?? "",
        lastName: user.lastName ?? "",
        email: user.email ?? "",
        phoneNumber: user.phoneNumber ?? "",
        profilePicture: {
          src: user.profilePicture,
          file: undefined,
        },
      })
    }
  }, [user])

  const onUpdateProfilePictureClick = useCallback(
    () => setUpdateProfilePictureVisible(true),
    [],
  )

  const onSubmit = useCallback(
    async (data: TForm) => {
      try {
        if (dirtyFields.profilePicture) {
          const formData = new FormData()
          formData.append("profilePicture", data.profilePicture?.file as File)
          await updateUserProfilePicture(formData)
        }
        if (
          dirtyFields.firstName ??
          dirtyFields.lastName ??
          dirtyFields.email ??
          dirtyFields.phoneNumber
        ) {
          await updateUser({
            ...user,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
          })
        }

        toast.show(t("profileSavedSuccessMessage"), "success")
      } catch (_) {}
    },
    [user, dirtyFields],
  )

  const handleDeleteUserClick = () => {
    setDeleteDialogOpen(true)
  }

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
  }

  const handleConfirmDelete = async () => {
    deleteUserPermanently()
    setDeleteDialogOpen(false)
  }

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      padding="24px"
      className="scroll"
    >
      <Box marginBottom="12px" display="flex" justifyContent="flex-end">
        <LoadingButton
          disabled={!isDirty}
          onClick={handleSubmit(onSubmit)}
          loading={isUserUpdating || isUserProfilePictureUpdating}
        >
          {t("save")}
        </LoadingButton>
      </Box>

      <Controller
        control={control}
        name="profilePicture"
        render={({ field: { value, onChange } }) => (
          <Box
            position="relative"
            alignSelf="flex-start"
            paddingRight="4px"
            paddingBottom="4px"
          >
            <UpdateProfilePictureDialog
              isVisible={isUpdateProfilePictureVisible}
              onConfirm={(file) => {
                onChange(file)
                setUpdateProfilePictureVisible(false)
              }}
              onCancel={() => setUpdateProfilePictureVisible(false)}
            />
            <Avatar src={value?.src} />
            <EditButton
              size="small"
              className="icon-button-contained"
              onClick={onUpdateProfilePictureClick}
            >
              <EditIcon fontSize="small" />
            </EditButton>
          </Box>
        )}
      />
      <Typography marginTop="24px" marginBottom="12px" variant="regularBold">
        {t("personalInformation")}
      </Typography>
      <Controller
        control={control}
        name="firstName"
        rules={{
          required: t("required"),
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            fullWidth
            value={value}
            onChange={onChange}
            label={t("firstName")}
            error={!!error?.message}
            helperText={error?.message}
            disabled={isUserUpdating}
          />
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        control={control}
        name="lastName"
        rules={{
          required: t("required"),
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            fullWidth
            value={value}
            onChange={onChange}
            label={t("lastName")}
            error={!!error?.message}
            helperText={error?.message}
            disabled={isUserUpdating}
          />
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        control={control}
        name="email"
        rules={{
          required: t("required"),
          pattern: {
            value: EMAIL_REGEX_VALIDATION,
            message: t("emailNotValid"),
          },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            fullWidth
            value={value}
            onChange={onChange}
            label={t("email")}
            type="email"
            error={!!error?.message}
            helperText={error?.message}
            disabled
          />
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        control={control}
        name="phoneNumber"
        rules={{
          required: t("required"),
          validate: (value) =>
            value?.length && !isValidPhoneNumber(value)
              ? t("invalidPhoneNumberErrorMessage")
              : true,
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <PhoneNumberInput
            fullWidth
            value={value}
            onChange={onChange}
            type="number"
            label={t("mobilePhone")}
            error={!!error?.message}
            helperText={error?.message}
            disabled={isUserUpdating}
          />
        )}
      />
      <Box
        display="flex"
        flex={1}
        paddingTop="12px"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <LoadingButton
          color="error"
          onClick={handleDeleteUserClick}
          loading={isDeletingUser}
        >
          {t("deleteUser")}
        </LoadingButton>
      </Box>

      <AlertDialog
        isVisible={deleteDialogOpen}
        message={t("areYouSureYouWantToDeleteUserPermanently")}
        confirmLabel={t("delete")}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  )
}
