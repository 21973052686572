import { Avatar, Box, IconButton, styled } from "@mui/material"
import { colors } from "../../utils"

interface IGroupBoxProps {
  selected?: boolean
}

export const GroupBox = styled(Box)<IGroupBoxProps>`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    position: relative;
    background-color: ${colors.white};
    border-radius: 3px;
    border: ${({ selected }) =>
      selected ? `1px solid ${colors.black}` : `1px solid ${colors.gray2}`};
    padding: 4px;
    padding-right: 8px;
    transition: all 0.2s ease-in-out;
    margin-bottom: 8px;
    cursor: pointer;
  
    ${({ selected }) =>
      !selected &&
      `&:hover {
      background-color: ${colors.gray};
      border: 1px solid ${colors.gray2};
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      `}}
  `

export const GroupLogo = styled(Avatar)`
  width: 46px;
  height: 46px;
  margin-right: 8px;
  border-radius: 3px;
  background-color: ${colors.primary};
  font-size: 14px;
  font-weight: 700;
`

export const ExpandIconButton = styled(IconButton)(() => ({
  "&.MuiIconButton-root": {
    padding: "2px",
    marginRight: "4px",
    "& .MuiSvgIcon-root": {
      fontSize: "26px !important",
    },
  },
}))
