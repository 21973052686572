import {
  Box,
  Stepper,
  Step,
  Radio,
  Button,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"
import { colors } from "../../../../utils"
import { useTranslation } from "react-i18next"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

interface IProps {
  activeSection: IClaimTemplateSection
  sections: IClaimTemplateSection[]
  sectionsWithError?: string[]
  sectionsCompleted?: string[]
  onSectionClick: (section: IClaimTemplateSection) => void
}

export const FormProgress = (props: IProps) => {
  const {
    activeSection,
    sections,
    sectionsWithError,
    sectionsCompleted,
    onSectionClick,
  } = props
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const isXl = useMediaQuery(breakpoints.up(1300))

  if (!isXl) {
    return null
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderBottom={`1px solid ${colors.gray13}`}
      padding="8px"
    >
      <Stepper nonLinear>
        {sections?.map((section) => {
          const isActive = !!activeSection && activeSection.id === section.id
          const hasError = !!sectionsWithError?.includes(section.id)
          const isCompleted = !!sectionsCompleted?.includes(section.id)

          return (
            <Step key={section.id}>
              <Button
                className="word-break-all"
                variant="text"
                disableRipple
                disableElevation
                color="inherit"
                sx={{
                  color: isActive ? colors.black2 : colors.gray3,
                  fontWeight: "600",
                  fontSize: "14px",
                }}
                startIcon={
                  <Tooltip title={hasError ? t("sectionErrorTooltip") : null}>
                    {!isActive && (isCompleted || hasError) ? (
                      <>
                        {isCompleted && (
                          <CheckCircleIcon
                            sx={{
                              width: "24px",
                              height: "24px",
                              padding: "9px",
                            }}
                            color="success"
                          />
                        )}
                        {hasError && (
                          <ErrorIcon
                            sx={{
                              width: "24px",
                              height: "24px",
                              padding: "9px",
                            }}
                            color="error"
                          />
                        )}
                      </>
                    ) : (
                      <Radio
                        size="medium"
                        checked={isActive}
                        color={
                          hasError
                            ? "error"
                            : isCompleted
                            ? "success"
                            : "primary"
                        }
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                      />
                    )}
                  </Tooltip>
                }
                onClick={() => onSectionClick(section)}
              >
                {section.name}
              </Button>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
