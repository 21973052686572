import { type TFunction } from "i18next"
import {
  EMAIL_REGEX_VALIDATION,
  NUMERIC_REGEX_VALIDATION,
} from "../../../utils"
import { type FieldValues, type RegisterOptions } from "react-hook-form"
import { isValidPhoneNumber } from "react-phone-number-input"
import dayjs from "dayjs"

export const getDamagedParty = (holder: {
  salutation: string | null
  company: string | null
  firstName: string | null
  lastName: string | null
}): string | null => {
  if (holder?.salutation === "Firma") {
    return holder?.company!
  }
  if (holder?.firstName || holder?.lastName) {
    return `${holder.firstName ?? ""} ${holder.lastName ?? ""}`
  }
  return null
}

export const isFieldRequired = (
  field: IClaimTemplateMetadata,
  conditionFieldValue: any,
) => {
  if (field.rules.isRequired) {
    let isConditionCorrect = false

    if (field.requiredConditions.value !== null) {
      isConditionCorrect =
        conditionFieldValue === field.requiredConditions.value
    }

    if (field.requiredConditions.exceptValue !== null) {
      isConditionCorrect =
        conditionFieldValue !== field.requiredConditions.exceptValue
    }

    return isConditionCorrect
  }

  return false
}

export const getRules = (
  field: IClaimTemplateMetadata,
  t: TFunction<"translation", undefined>,
  isConditionallyRequired?: boolean,
  connectedFieldValue?: any,
) => {
  let rules:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
      >
    | undefined = {
    ...(!!field.rules.isRequired &&
      field.requiredConditions === null && {
        required: {
          value: true,
          message: t("required"),
        },
      }),
    ...(!!field.rules.maxLength && {
      maxLength: {
        value: field.rules.maxLength,
        message: t("maxLengthErrorMessage", { value: field.rules.maxLength }),
      },
    }),
    ...(!!field.rules.minLength && {
      minLength: {
        value: field.rules.minLength,
        message: t("minLengthErrorMessage", { value: field.rules.minLength }),
      },
    }),
    ...(!!field.rules.pattern && {
      pattern: {
        value: field.rules.pattern,
        message: t("invalidPatternErrorMessage"),
      },
    }),
  }

  if (field.fieldType === "Email") {
    rules = {
      ...rules,
      pattern: {
        value: EMAIL_REGEX_VALIDATION,
        message: t("emailNotValid"),
      },
    }
  }

  rules = {
    ...rules,
    validate: (value) => {
      if (!value && isConditionallyRequired) {
        return t("required")
      } else if (
        (field.fieldType === "Number" || field.fieldType === "Currency") &&
        value &&
        !(
          typeof value === "number" &&
          !isNaN(value) &&
          NUMERIC_REGEX_VALIDATION.test(value?.toString())
        )
      ) {
        return t("onlyDigitsErrorMessage")
      } else if (
        field.fieldType === "PhoneNumber" &&
        value?.length &&
        !isValidPhoneNumber(value)
      ) {
        return t("invalidPhoneNumberErrorMessage")
      } else if (
        field.rules.requiredValue !== null &&
        value !== field.rules.requiredValue
      ) {
        return field.rules.requiredMessage
      } else if (
        value &&
        field.fieldType === "Date" &&
        field.rules.isDateInPast &&
        dayjs(value).isAfter(dayjs(), "day")
      ) {
        return t("dateInPastError")
      } else if (
        value &&
        field.fieldType === "Time" &&
        field.rules.isDateInPast &&
        connectedFieldValue &&
        dayjs(connectedFieldValue).isValid() &&
        dayjs(connectedFieldValue).isSame(dayjs(), "day") &&
        dayjs(value).isAfter(dayjs(), "minute")
      ) {
        return t("timeInPastError")
      }

      return true
    },
  }

  return rules
}
