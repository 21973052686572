import { useState, useRef, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  debounce,
  Grow,
  InputAdornment,
  Paper,
  Radio,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

import { useQuery } from "@tanstack/react-query"
import SearchIcon from "@mui/icons-material/Search"
import { getOrganizationsAPI } from "../../services"
import { CustomPopper, RectangularButton } from "./styled"
import {
  colors,
  filterOrganizationsBySearch,
  findActiveGroup,
  findAllSubgroupsSelected,
} from "../../utils"
import { GroupLineItem } from "../../components"
import { useAppContext } from "../../contexts"
import { useWindowDimensions } from "../../hooks"

interface IProps {
  multiselect?: boolean
  prioOrganizations?: IOrganization[]
  allowEmptySelection?: boolean
  showClearFiltersButton?: boolean
  groups: string[]
  onUpdate: (selectedGroups: string[]) => void
}

export const GroupSwitchPopup = (props: IProps) => {
  const {
    multiselect = true,
    groups,
    prioOrganizations,
    allowEmptySelection = false,
    showClearFiltersButton,
    onUpdate,
  } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const [search, setSearch] = useState("")
  const anchorRef = useRef<HTMLDivElement>(null)
  const { state: appState } = useAppContext()
  const { height } = useWindowDimensions()

  const isGreaterThanSM = useMediaQuery(theme.breakpoints.up("sm"))
  const isSmallerThanMD = useMediaQuery(theme.breakpoints.down("md"))

  const [open, setOpen] = useState(false)

  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    groups?.length ? groups : [],
  )

  const { data } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => getOrganizationsAPI(),
    enabled: !prioOrganizations,
  })

  const organizations = prioOrganizations ?? data

  const handleToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), [])

  const handleCancel = useCallback(() => {
    setOpen(false)
    setSelectedGroups(groups ?? [])
  }, [groups])

  const handleClose = useCallback(
    (event: Event) => {
      if (anchorRef?.current?.contains(event.target as HTMLElement)) {
        return
      }
      handleCancel()
    },
    [handleCancel],
  )

  const buttonLabel = useMemo(() => {
    if (selectedGroups.length === 0) {
      return (
        <Typography variant="large" color={colors.gray2}>
          {t("groupFilter")}
        </Typography>
      )
    } else {
      const lastSelectedGroupName = findActiveGroup(
        organizations ?? [],
        selectedGroups[selectedGroups.length - 1],
      )?.name
      const selectedCount = selectedGroups.length
      if (lastSelectedGroupName) {
        if (selectedCount > 1) {
          return (
            <Typography>{`${lastSelectedGroupName}  (+${
              selectedCount - 1
            })`}</Typography>
          )
        } else {
          return <Typography>{lastSelectedGroupName}</Typography>
        }
      }
    }
  }, [selectedGroups, organizations])

  const isSelected = useCallback(
    (id: string) => selectedGroups.includes(id),
    [selectedGroups],
  )

  const handleGroupChange = useCallback(
    (group: IGroup) => {
      if (multiselect) {
        if (isSelected(group.id)) {
          setSelectedGroups(
            selectedGroups.filter((groupId) => groupId !== group.id),
          )
        } else {
          let nextSelectedGroups = [...selectedGroups]

          const selectedSubgroups = findAllSubgroupsSelected(
            group,
            selectedGroups,
          )

          if (selectedSubgroups?.length) {
            nextSelectedGroups = nextSelectedGroups?.filter(
              (g) => !selectedGroups.includes(g),
            )
          }

          setSelectedGroups([...nextSelectedGroups, group.id])
        }
      } else {
        setSelectedGroups((prev) => (prev?.[0] === group.id ? [] : [group.id]))
      }
    },
    [selectedGroups, multiselect, isSelected],
  )

  const handleSelectAll = useCallback(
    () =>
      setSelectedGroups(
        organizations
          ?.map((o) => o.groups)
          ?.flat()
          ?.map((g) => g.id) ?? [],
      ),
    [organizations],
  )

  const handleClearFilters = useCallback(() => {
    setSelectedGroups([appState?.groupId!])
    setOpen(false)
    onUpdate([appState?.groupId!])
  }, [appState])

  const handleSwitchGroup = useCallback(() => {
    onUpdate(selectedGroups)
    setOpen(false)
  }, [selectedGroups, onUpdate])

  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearch(text)
    }, 350),
    [],
  )

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSearch(event.target.value)
    },
    [],
  )

  const filteredOrganizations = useMemo(() => {
    let nextOrganizations = organizations ? [...organizations] : undefined

    if (nextOrganizations?.length) {
      if (search) {
        nextOrganizations = filterOrganizationsBySearch(
          nextOrganizations,
          search,
        )
      }

      nextOrganizations = nextOrganizations.sort((a, b) =>
        a.name.localeCompare(b.name),
      )
    }

    return nextOrganizations
  }, [organizations, search])

  return (
    <>
      <ButtonGroup variant="text" ref={anchorRef}>
        <RectangularButton
          onClick={handleToggle}
          endIcon={
            !open ? (
              <ExpandMoreIcon color="action" />
            ) : (
              <ExpandLessIcon color="action" />
            )
          }
        >
          <Typography>{buttonLabel}</Typography>
        </RectangularButton>
      </ButtonGroup>
      <CustomPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
        style={{
          zIndex: 1500,
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleClose}>
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "top left",
              }}
            >
              <Paper>
                <Box
                  minWidth="360px"
                  maxWidth={isSmallerThanMD ? "50vw" : "600px"}
                >
                  <Box
                    padding="16px"
                    marginBottom="8px"
                    bgcolor={colors.secondary}
                  >
                    <Typography variant="regularBold">
                      {t("groupSelectionFilter")}
                    </Typography>
                  </Box>
                  <Box
                    paddingX="16px"
                    marginBottom="8px"
                    display="flex"
                    alignItems="center"
                    gap="16px"
                  >
                    {multiselect && (
                      <Box display="flex" alignItems="center">
                        <Typography marginRight="8px" variant="regularBold">
                          {t("select")}:
                        </Typography>
                        <Radio
                          checked={
                            selectedGroups.length ===
                            organizations?.flatMap((org) => org.groups || [])
                              .length
                          }
                          onChange={handleSelectAll}
                        />
                        <Typography>{t("allGroups")}</Typography>
                      </Box>
                    )}
                    <TextField
                      sx={{ flex: 1 }}
                      variant="outlined"
                      placeholder={t("search")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleSearch}
                    />
                  </Box>
                  <Box
                    className="scroll"
                    maxHeight="340px"
                    padding="16px"
                    paddingTop="0px"
                  >
                    {!filteredOrganizations?.length && (
                      <Box
                        display="flex"
                        flex={1}
                        justifyContent="center"
                        alignItems="center"
                        height={height < 500 ? "auto" : "150px"}
                      >
                        <Typography textAlign="center">
                          {search?.length
                            ? t("noOrganizationsBySearch")
                            : t("noOrganizations")}
                        </Typography>
                      </Box>
                    )}
                    {!!filteredOrganizations?.length &&
                      filteredOrganizations.map((organization) => (
                        <Box
                          key={organization.id}
                          gap="8px"
                          display="flex"
                          flexDirection="column"
                        >
                          <Typography textAlign="start" variant="regularBold">
                            {organization.name}
                          </Typography>
                          {organization?.groups
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            ?.map((group) => (
                              <GroupLineItem
                                key={group.id}
                                group={group}
                                selectedGroupIds={selectedGroups}
                                onChangeGroup={handleGroupChange}
                                isTopParent
                                multiselect
                              />
                            ))}
                          <Box marginTop="2px" />
                        </Box>
                      ))}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={isGreaterThanSM ? "row" : "column"}
                    padding="16px"
                    gap="8px"
                  >
                    {showClearFiltersButton && (
                      <Button
                        variant="text"
                        color="error"
                        onClick={handleClearFilters}
                      >
                        {t("clearFilters")}
                      </Button>
                    )}
                    <Box flex={1} />
                    <Box
                      display="flex"
                      flexDirection={isGreaterThanSM ? "row" : "column"}
                      gap="8px"
                    >
                      <Button variant="outlined" onClick={handleCancel}>
                        {t("cancel")}
                      </Button>
                      <Button
                        onClick={handleSwitchGroup}
                        disabled={
                          !allowEmptySelection && selectedGroups.length < 1
                        }
                      >
                        {t("update")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </CustomPopper>
    </>
  )
}
