import React from "react"
import {
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  Collapse,
  TableHead,
} from "@mui/material"
import { Controller, useFieldArray } from "react-hook-form"
import { AddCircleOutlined, RemoveCircleOutlined } from "@mui/icons-material"
import { t } from "i18next"
import { colors } from "../../../../../utils"
import { CustomTextField } from "../styled"
import { CustomSubTableDetails } from "./styled"

export const SubsDetails: React.FC<any> = ({
  control,
  parentIndex,
  subIndex,
  isEditMode,
  expandedSubDetails,
}) => {
  const {
    fields: subDetailFields,
    append: appendSubDetail,
    remove,
  } = useFieldArray({
    control,
    name: `claimCosts.${parentIndex}.subs.${subIndex}.subDetails`,
  })

  return (
    <>
      <TableRow>
        <TableCell
          style={{
            padding: "0px",
            paddingBottom: "20px",
          }}
          colSpan={6}
        >
          <Collapse
            style={{ margin: "0px", backgroundColor: `${colors.gray4}` }}
            in={expandedSubDetails === subIndex}
            timeout="auto"
            unmountOnExit
          >
            <CustomSubTableDetails isEditMode={isEditMode}>
              <TableHead>
                <TableRow>
                  <TableCell width="21.25%" align="right">
                    <Typography>{t("paymentType")}</Typography>
                  </TableCell>
                  <TableCell width="21.25%" align="right">
                    <Typography>{t("amount")}</Typography>
                  </TableCell>
                  <TableCell width="21.25%" />
                  <TableCell width="21.25%" />
                  <TableCell width="21.25%" colSpan={!isEditMode ? 2 : 1} />
                  {isEditMode && (
                    <TableCell align="right">
                      {isEditMode && (
                        <IconButton
                          color="default"
                          onClick={() =>
                            appendSubDetail({ paymentType: "", amount: 0 })
                          }
                        >
                          <AddCircleOutlined />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {subDetailFields.length === 0 && isEditMode ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      {t("noData")}
                    </TableCell>
                  </TableRow>
                ) : (
                  subDetailFields.map((subDetail: any, subDetailIndex) => (
                    <TableRow key={subDetail.id || subDetailIndex}>
                      <TableCell align="right">
                        {isEditMode ? (
                          <Controller
                            control={control}
                            name={`claimCosts.${parentIndex}.subs.${subIndex}.subDetails.${subDetailIndex}.paymentType`}
                            render={({ field }) => (
                              <CustomTextField size="small" {...field} />
                            )}
                          />
                        ) : (
                          <Typography>{subDetail.paymentType}</Typography>
                        )}
                      </TableCell>

                      <TableCell align="right">
                        {isEditMode ? (
                          <Controller
                            control={control}
                            name={`claimCosts.${parentIndex}.subs.${subIndex}.subDetails.${subDetailIndex}.amount`}
                            render={({ field }) => (
                              <CustomTextField
                                size="small"
                                type="number"
                                {...field}
                              />
                            )}
                          />
                        ) : (
                          <Typography>{subDetail.amount}</Typography>
                        )}
                      </TableCell>
                      <TableCell />
                      <TableCell colSpan={!isEditMode ? 2 : 1} />
                      {isEditMode && (
                        <TableCell colSpan={2} align="right">
                          {isEditMode && (
                            <IconButton
                              color="error"
                              onClick={() => remove(subDetailIndex)}
                            >
                              <RemoveCircleOutlined />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </CustomSubTableDetails>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
