import {
  Alert,
  Box,
  Collapse,
  Grid,
  IconButton,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material"
import { memo, useMemo } from "react"
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined"
import { colors } from "../../../../utils"
import { DocumentPreviewDescription } from "../../../../components"
import { Trans, useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { createSearchParams, Link } from "react-router-dom"
import { type ISectionItemProps } from "../../types"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import ErrorIcon from "@mui/icons-material/Error"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { FormFieldsList } from "../FormFieldsList"

export const SubsectionItem = memo((props: ISectionItemProps) => {
  const {
    claim,
    claimFiles,
    subsection,
    disabled,
    selectedSubsections,
    subsectionsWithError,
    isComparisonPreview,
    conflictedSectionIds,
    conflictedColor,
    subsectionsCompleted,
    onSubsectionClick,
    onRemoveSubsectionClick,
  } = props
  const { t } = useTranslation()

  const isExpanded =
    !!isComparisonPreview || !!selectedSubsections?.includes(subsection?.id!)

  const magicEmailLinkInfo = useMemo(() => {
    return claim?.magicLinkInformation
      ?.filter(
        (i) =>
          i.documentType === subsection?.sectionType &&
          !i.isUsedForSigning &&
          // dayjs(new Date()).isBefore(new Date(i.expiresOn)) &&
          i.communicationChannel === "Email",
      )
      ?.sort(
        (a, b) =>
          new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
      )
      ?.pop()
  }, [claim, subsection])

  const magicSMSLinkInfo = useMemo(() => {
    return claim?.magicLinkInformation
      ?.filter(
        (i) =>
          i.documentType === subsection?.sectionType &&
          !i.isUsedForSigning &&
          // dayjs(new Date()).isBefore(new Date(i.expiresOn)) &&
          i.communicationChannel === "SMS",
      )
      ?.sort(
        (a, b) =>
          new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
      )
      ?.pop()
  }, [claim, subsection])

  const shouldDisableDeleteButton = useMemo(
    () =>
      claimFiles?.some(
        (d) => d?.documentType === subsection?.sectionType && !!d.files?.length,
      ),
    [subsection?.sectionType, claimFiles],
  )

  const shouldShowSubsectionDescription = useMemo(
    () =>
      !!subsection?.description &&
      (!subsection?.isDocumentSection ||
        subsection?.sectionType === "DamageDescription"),
    [subsection],
  )

  const shouldShowDocumentExplanation = useMemo(
    () =>
      !!subsection?.isDocumentSection &&
      subsection?.sectionType !== "DamageDescription" &&
      subsection?.sectionType !== "Other",
    [subsection],
  )

  const isSubsectionConflicted = useMemo(
    () =>
      isComparisonPreview && conflictedSectionIds?.includes(subsection?.id!),
    [subsection],
  )

  const hasError = useMemo(
    () => !!subsectionsWithError?.includes(subsection?.id!),
    [subsectionsWithError, subsection],
  )

  const isCompleted = useMemo(
    () => !!subsectionsCompleted?.includes(subsection?.id!),
    [subsectionsWithError, subsection],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius="8px"
      border={`1px solid ${colors.gray13}`}
    >
      <Box
        display="flex"
        alignItems="center"
        flex={1}
        borderRadius="8px 8px 0px 0px"
        bgcolor={isExpanded ? colors.gray8 : colors.transparent}
        padding="10px 16px"
        className="pointer"
        onClick={() => onSubsectionClick?.(subsection?.id!)}
      >
        <Tooltip title={hasError ? t("sectionErrorTooltip") : null}>
          <Box component="span" marginRight="16px">
            {((!hasError && !isCompleted) || isExpanded) && (
              <Radio
                size="small"
                checked={isExpanded}
                color={
                  isComparisonPreview
                    ? "default"
                    : hasError
                    ? "error"
                    : isCompleted
                    ? "success"
                    : "primary"
                }
              />
            )}
            {hasError && !isExpanded && (
              <ErrorIcon fontSize="small" color="error" />
            )}
            {isCompleted && !isExpanded && (
              <CheckCircleIcon fontSize="small" color="success" />
            )}
          </Box>
        </Tooltip>
        <Box flex={1} display="flex" alignItems="center" gap="6px">
          <Typography variant="smallSemiBold">{subsection!.name}</Typography>
          {!!subsection?.description && !isExpanded && (
            <Tooltip title={subsection.description}>
              <InfoOutlinedIcon fontSize="small" htmlColor={colors.gray3} />
            </Tooltip>
          )}
        </Box>

        {isExpanded ? (
          <ExpandCircleDownOutlinedIcon
            htmlColor={colors.black2}
            sx={{ transform: "rotate(180deg)" }}
          />
        ) : (
          <ExpandCircleDownOutlinedIcon htmlColor={colors.black2} />
        )}
        <IconButton
          sx={{ marginLeft: "8px" }}
          color="error"
          disabled={disabled || shouldDisableDeleteButton}
          onClick={() => onRemoveSubsectionClick?.(subsection!)}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Box>

      <Collapse
        in={isExpanded}
        timeout="auto"
        // unmountOnExit
      >
        <Box
          padding="16px"
          bgcolor={isSubsectionConflicted ? conflictedColor : "transparent"}
          borderRadius="0px 0px 8px 8px"
        >
          <Grid container spacing="24px">
            {shouldShowSubsectionDescription && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={isComparisonPreview ? 12 : 3}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  // justifyContent="center"
                  // flex={1}
                  // height="100%"
                >
                  {!!subsection?.description && (
                    <Typography variant="small">
                      {subsection?.description}
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={
                shouldShowSubsectionDescription && !isComparisonPreview ? 9 : 12
              }
            >
              {shouldShowDocumentExplanation && <DocumentPreviewDescription />}

              {!!magicEmailLinkInfo && (
                <>
                  <Alert
                    severity={
                      dayjs(new Date()).isBefore(
                        new Date(magicEmailLinkInfo.expiresOn),
                      )
                        ? "info"
                        : "warning"
                    }
                  >
                    <Trans
                      i18nKey="emailLinkDescription"
                      values={{
                        expiresOn: dayjs(magicEmailLinkInfo.expiresOn).format(
                          "DD.MM.YYYY HH:mm",
                        ),
                        createdOn: dayjs(magicEmailLinkInfo.createdOn).format(
                          "DD.MM.YYYY HH:mm",
                        ),
                        customer: magicEmailLinkInfo.sentTo,
                      }}
                      components={[
                        <Link
                          key="1"
                          className={disabled ? "disabled" : ""}
                          to={{
                            pathname: "../document-preview",
                            search: createSearchParams({
                              claimId: claim.id,
                              documentType: subsection?.sectionType!,
                            }).toString(),
                          }}
                          state={{
                            isFromLink: true,
                          }}
                        ></Link>,
                      ]}
                    />
                  </Alert>
                </>
              )}

              {!!magicSMSLinkInfo && (
                <>
                  {!!magicEmailLinkInfo && <Box marginTop="8px" />}
                  <Alert
                    severity={
                      dayjs(new Date()).isBefore(
                        new Date(magicSMSLinkInfo.expiresOn),
                      )
                        ? "info"
                        : "warning"
                    }
                  >
                    <Trans
                      i18nKey="smsLinkDescription"
                      values={{
                        expiresOn: dayjs(magicSMSLinkInfo.expiresOn).format(
                          "DD.MM.YYYY HH:mm",
                        ),
                        createdOn: dayjs(magicSMSLinkInfo.createdOn).format(
                          "DD.MM.YYYY HH:mm",
                        ),
                        customer: magicSMSLinkInfo.sentTo,
                      }}
                      components={[
                        <Link
                          key="1"
                          className={disabled ? "disabled" : ""}
                          to={{
                            pathname: "../document-preview",
                            search: createSearchParams({
                              claimId: claim.id,
                              documentType: subsection?.sectionType!,
                            }).toString(),
                          }}
                          state={{
                            isFromLink: true,
                          }}
                        ></Link>,
                      ]}
                    />
                  </Alert>
                </>
              )}

              {(!!magicEmailLinkInfo || !!magicSMSLinkInfo) && (
                <Box marginTop="24px" />
              )}

              {!!subsection?.metadatas?.length && <FormFieldsList {...props} />}
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  )
})
