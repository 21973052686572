import React, { useEffect, useMemo, useState } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import { Controller, useForm } from "react-hook-form"
import { useToast } from "../../contexts"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { updateGroupAPI } from "../../services"
import {
  CITY_REGEX_VALIDATION,
  NUMERIC_REGEX_VALIDATION,
  updateGroupInNestedStructure,
  URL_REGEX,
} from "../../utils"
import { StyledCheckbox } from "./styled"

interface IProps {
  isVisible: boolean
  groupData?: IGroup
  onClose: () => void
}

const UpdateGroupModal: React.FC<IProps> = (props: IProps) => {
  const { isVisible, groupData, onClose } = props
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [isOverridingGroupSettings, setIsOverridingGroupSettings] =
    useState(false)

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const hasPermissionForOverridingSettings = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "OVERWRITE_GROUP_SETTINGS" && p.access === "Delete",
      ),
    [user],
  )

  const organizationsQueryParams = {
    includeDisabledGroups: false,
    groupManagementTab: false,
    ownedGroups: true,
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ICreateGroupBody>({
    defaultValues: {
      name: groupData?.name || "",
      organizationId: "",
      // isEnabled: groupData?.isEnabled,
      externalDetails: groupData?.externalDetails || {
        addressNr: 0,
        referat: 0,
        lawyer: "",
        contactPersonFullName: "",
        contactPersonId: 0,
      },
      isOverridingGroupSettings: groupData?.isOverridingGroupSettings,
      city: groupData?.city || null,
      baseUrl: groupData?.baseUrl || null,
    },
  })

  useEffect(() => {
    if (isVisible && groupData) {
      reset({
        name: groupData.name || "",
        isEnabled: groupData.isEnabled,
        externalDetails: groupData.externalDetails || {
          addressNr: undefined,
          referat: undefined,
          lawyer: "",
          contactPersonFullName: "",
          contactPersonId: undefined,
        },
        isOverridingGroupSettings: groupData?.isOverridingGroupSettings,
        city: groupData.city || null,
        baseUrl: groupData.baseUrl || null,
      })
    }
  }, [isVisible, groupData, reset])

  const { mutate: updateGroup, isPending: isUpdating } = useMutation({
    mutationFn: (data: ICreateGroupBody) =>
      updateGroupAPI(groupData?.id as string, data),
    onSuccess: (updatedGroup: IGroup) => {
      toast.show(t("groupUpdated"), "success")

      queryClient.setQueryData<IOrganization[]>(
        ["organizations-disabled"],
        (oldData) => {
          return oldData?.map((organization) => {
            return {
              ...organization,
              groups: updateGroupInNestedStructure(
                organization.groups,
                groupData?.id!,
                updatedGroup,
              ),
            }
          })
        },
      )

      void queryClient.refetchQueries({ queryKey: ["organizations"] })
      void queryClient.refetchQueries({
        queryKey: ["organizations-disabled-managed", organizationsQueryParams],
      })

      onClose()
    },
    onError: () => {
      toast.show(t("errorUpdatingGroup"), "error")
    },
  })

  const onFormSubmit = (data: ICreateGroupBody) => {
    let baseUrl = data.baseUrl
    if (baseUrl?.endsWith("/")) {
      baseUrl = baseUrl.slice(0, -1)
    }

    const payload: ICreateGroupBody = {
      ...data,
      isEnabled: true,
      city: data.city === "" ? null : data.city,
      baseUrl: baseUrl === "" ? null : baseUrl,
      externalDetails: isOverridingGroupSettings
        ? {
            ...data.externalDetails,
            contactPersonFullName:
              data?.externalDetails?.contactPersonFullName || null,
            contactPersonId: data?.externalDetails?.contactPersonId || null,
          }
        : (null as any),
    }

    updateGroup(payload)
  }

  useEffect(() => {
    if (isVisible && groupData) {
      setIsOverridingGroupSettings(groupData.isOverridingGroupSettings || false)
    }
  }, [isVisible, groupData?.isOverridingGroupSettings])

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>{t("editGroup")}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="16px">
          <Controller
            name="name"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("groupName")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />

          <Controller
            name="city"
            control={control}
            rules={{
              pattern: {
                value: CITY_REGEX_VALIDATION,
                message: t("onlyLettersErrorMessage"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("city")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="baseUrl"
            control={control}
            rules={{
              pattern: {
                value: URL_REGEX,
                message: t("invalidUrl"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("baseUrl")}
                placeholder="https://"
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          {/* <Controller
            name="isEnabled"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <StyledCheckbox
                    {...field}
                    defaultChecked={groupData?.isEnabled}
                  />
                }
                label={t("enableGroup")}
                labelPlacement="end"
              />
            )}
          /> */}

          {hasPermissionForOverridingSettings && (
            <Controller
              name="isOverridingGroupSettings"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={isOverridingGroupSettings}
                      onChange={(e) => {
                        field.onChange(e.target.checked)
                        setIsOverridingGroupSettings(e.target.checked)
                      }}
                    />
                  }
                  label={t("overwriteOrganizationSettings")}
                  labelPlacement="end"
                />
              )}
            />
          )}
          {isOverridingGroupSettings && (
            <>
              <Controller
                name="externalDetails.addressNr"
                control={control}
                rules={{
                  required: {
                    value: isOverridingGroupSettings,
                    message: t("required"),
                  },
                  min: {
                    value: 0,
                    message: t("minNumberErrorMessage", {
                      value: 0,
                    }),
                  },
                  pattern: {
                    value: NUMERIC_REGEX_VALIDATION,
                    message: t("onlyDigitsErrorMessage"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("addressNr")}
                    error={!!error?.message}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="externalDetails.referat"
                control={control}
                rules={{
                  required: {
                    value: isOverridingGroupSettings,
                    message: t("required"),
                  },
                  min: {
                    value: 0,
                    message: t("minNumberErrorMessage", {
                      value: 0,
                    }),
                  },
                  pattern: {
                    value: NUMERIC_REGEX_VALIDATION,
                    message: t("onlyDigitsErrorMessage"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("referat")}
                    error={!!error?.message}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="externalDetails.lawyer"
                control={control}
                rules={{
                  required: isOverridingGroupSettings && t("required"),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={t("lawyer")}
                    error={!!error?.message}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="externalDetails.contactPersonFullName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={t("contactPersonFullName")}
                    error={!!error?.message}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="externalDetails.contactPersonId"
                control={control}
                rules={{
                  pattern: {
                    value: NUMERIC_REGEX_VALIDATION,
                    message: t("onlyDigitsErrorMessage"),
                  },
                  min: {
                    value: 0,
                    message: t("minNumberErrorMessage", {
                      value: 0,
                    }),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("contactPersonId")}
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton
          onClick={handleSubmit(onFormSubmit)}
          loading={isSubmitting || isUpdating}
        >
          {t("save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateGroupModal
