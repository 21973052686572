import { Box, styled } from "@mui/material"
import { colors } from "../../../../../../utils"

interface IButtonProps {
  isSelected: boolean
}

export const ChannelButton = styled(Box)<IButtonProps>`
  display: flex;
  align-items: center;
  height: 100%;
  max-height: -webkit-fill-available;
  padding: 12px;
  border-radius: 8px;
  background-color: ${(props: IButtonProps) =>
    props.isSelected ? colors.blue5 : colors.white2};
  border: ${(props: IButtonProps) =>
    props.isSelected
      ? `2px solid ${colors.primary}`
      : `2px dashed ${colors.primary}`};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${(props: IButtonProps) =>
      !props.isSelected && colors.blue6};
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  }
`

export const DocumentButton = styled(Box)<IButtonProps>`
  display: flex;
  align-items: center;
  height: 100%;
  max-height: -webkit-fill-available;
  padding: 12px;
  border-radius: 8px;
  background-color: ${(props: IButtonProps) =>
    props.isSelected ? colors.blue2 : colors.white2};
  border: 1px solid ${colors.blue4};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${(props: IButtonProps) =>
      !props.isSelected && colors.blue};
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  }
`
