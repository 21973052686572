import { Box, Typography } from "@mui/material"
import { type IFieldItemControllerProps } from "../../../../types"
import { FilePreview } from "../../../../../../components"
import { createSearchParams, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { memo } from "react"

export const FileDownloadController = memo(
  (props: IFieldItemControllerProps) => {
    const { claim, field, subsection, isDisabled, isClaimAutoSaving } = props
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
      <Box display="flex" flexDirection="column">
        <FilePreview
          fileName={field.label}
          onPreview={() => {
            navigate({
              pathname: "../document-preview",
              search: createSearchParams({
                claimId: claim.id,
                documentType: subsection?.sectionType!,
              }).toString(),
            })
          }}
          disabled={isDisabled || !!isClaimAutoSaving}
        />
        {!!isClaimAutoSaving && (
          <Box display="flex" justifyContent="center" marginTop="4px">
            <Typography textAlign="center" variant="extraSmallMedium">
              {`(${t("syncingData")})`}
            </Typography>
          </Box>
        )}
      </Box>
    )
  },
)
