import { styled } from "@mui/material/styles"
import { ListItem, Avatar } from "@mui/material"
import { colors } from "../../../../utils"
import { type Transform } from "@dnd-kit/utilities"

interface StyledListItemProps {
  isDragging: boolean
  transform: Transform | null
}

export const StyledListItem = styled(ListItem)<StyledListItemProps>(
  ({ isDragging, transform }) => ({
    paddingLeft: 7.5,
    paddingRight: 7.5,
    backgroundColor: colors.white,
    border: `1.5px solid ${isDragging ? colors.blue3 : colors.gray5}`,
    marginTop: "10px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    position: isDragging ? "fixed" : "relative",
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0) rotate(-3.5deg)`
      : undefined,
    zIndex: isDragging ? 1000 : "auto",
    pointerEvents: "auto",
    width: isDragging ? "403px" : "auto",
    height: isDragging ? "70px" : "auto",
    cursor: isDragging ? "grabbing" : "grab",
    "&:hover": {
      border: `1.5px solid ${isDragging ? colors.blue3 : colors.primary}`,
    },
  }),
)

export const StyledAvatar = styled(Avatar)(() => ({
  backgroundColor: colors.gray6,
  color: colors.black2,
  marginRight: "10px",
  fontSize: "20px",
}))
