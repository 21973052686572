import { useCallback, useMemo } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { getOrganizationsAPI, switchActiveGroupAPI } from "../../services"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "../../contexts"
import { findActiveGroup } from "../../utils"
import { GroupSelectDialog } from "../GroupSelectDialog"
import { useTranslation } from "react-i18next"

interface IProps {
  open: boolean
  closeDialog: () => void
}

export const GroupSwitchDialog = (props: IProps) => {
  const { open, closeDialog } = props
  const { t } = useTranslation()

  const { data: organizations } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => getOrganizationsAPI(),
  })
  const navigate = useNavigate()
  const { state: appState } = useAppContext()

  const { mutate, isPending: switchingPending } = useMutation({
    mutationFn: (id: string) => switchActiveGroupAPI(id),
    onSuccess: (_, variables) => {
      sessionStorage.setItem("groupId", variables)
      closeDialog()
      navigate("/")
      window.location.reload()
    },
  })

  const activeGroup = useMemo(() => {
    if (organizations?.length && appState?.groupId) {
      return findActiveGroup(organizations, appState?.groupId)
    }

    return undefined
  }, [organizations, appState?.groupId])

  const handleSwitchGroup = useCallback(
    (groups: string[]) => {
      const selectedGroup = groups?.[0]
      if (selectedGroup && selectedGroup !== activeGroup?.id) {
        mutate(selectedGroup)
      }
    },
    [activeGroup],
  )

  return (
    <GroupSelectDialog
      isVisible={open}
      groups={activeGroup?.id ? [activeGroup?.id] : []}
      isSubmitButtonLoading={switchingPending}
      submitButtonLabel={t("switchGroup")}
      onSubmit={handleSwitchGroup}
      onClose={closeDialog}
    />
  )
}
