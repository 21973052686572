import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import {
  ForgotPasswordPage,
  LoginPage,
  ResetPasswordPage,
  VerificationPage,
  RegisterPage,
  DocumentPreviewPage,
  TrackingLandingPage,
  TrackingLegalNotice,
  TrackingPrivacyPolicy,
  TrackingClaimsPage,
  TrackingContactForm,
  RegisterOrganizationPage,
  InvitationVerificationPage,
} from "../../../pages"
import { BaseLayout } from "./layouts"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { TrackingLayout } from "../layouts"
import { useAppContext } from "../../../contexts"

const RoutesRouter = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { state: appState } = useAppContext()

  useEffect(() => {
    let title = t("crashMate")

    if (pathname === "/login") {
      title = `${t("crashMate")} - ${t("signIn")}`
    } else if (pathname === "/register") {
      title = `${t("crashMate")} - ${t("signUp")}`
    } else if (pathname === "/forgot-password") {
      title = `${t("crashMate")} - ${t("forgotPassword")}`
    } else if (pathname === "/reset-password") {
      title = `${t("crashMate")} - ${t("resetPassword")}`
    } else if (pathname === "/verify-email") {
      title = `${t("crashMate")} - ${t("verification")}`
    } else if (pathname.includes("tracking-overview")) {
      title = t("trackingOverview")
    } else {
      title = t("crashMate")
    }

    document.title = title
  }, [pathname, t])

  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route
        path="organisation-register"
        element={<RegisterOrganizationPage />}
      />

      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="verify-email" element={<VerificationPage />} />
      <Route
        path="invitation-verification"
        element={<InvitationVerificationPage />}
      />
      <Route
        path="public/document-preview"
        element={<DocumentPreviewPage key="document-preview" />}
      />
      <Route
        path="public/tracking-overview/*"
        element={
          <TrackingLayout>
            <Routes>
              <Route path="" element={<TrackingLandingPage />} />
              {appState.trackingAuthorization && (
                <Route path="claims" element={<TrackingClaimsPage />} />
              )}
              <Route path="start-new-query" element={<TrackingLandingPage />} />
              <Route path="contact-form" element={<TrackingContactForm />} />
              <Route path="legal-notice" element={<TrackingLegalNotice />} />
              <Route
                path="privacy-policy"
                element={<TrackingPrivacyPolicy />}
              />
            </Routes>
          </TrackingLayout>
        }
      />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  )
}

export const UnauthorizedRouter = () => (
  <BrowserRouter>
    <BaseLayout>
      <RoutesRouter />
    </BaseLayout>
  </BrowserRouter>
)
