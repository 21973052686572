import { useCallback, useEffect, useState } from "react"
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import { type FieldValues, useForm, Controller } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { ReactComponent as SuccessCheckIcon } from "../../assets/icons/success-check.svg"
import { colors } from "../../utils"
import { ClickableLabel, CodeInput } from "../../components"
import { resendEmailAPI, resendOtpAPI, verifyEmailAPI } from "../../services"
import { LoadingButton } from "@mui/lab"
import { useAppContext, useToast } from "../../contexts"
import { UpdatePhoneNumber } from "./components"

export const VerificationPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isOtpFormVisible, setOtpFormVisible] = useState(false)
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("")
  const [userIdFromEmail, setUserIdFromEmail] = useState("")
  const [email, setEmail] = useState("")
  const [isUpdatePhoneModalOpen, setUpdatePhoneModalOpen] = useState(false)
  const [isEmailVerificationFailed, setIsEmailVerificationFailed] =
    useState(false)
  const [isTokenExpired, setIsTokenExpired] = useState(false)
  const [searchParams] = useSearchParams()
  const toast = useToast()
  const { verifyOtpMutation } = useAppContext()

  const {
    mutate: verifyOtp,
    isSuccess: isOtpVerified,
    isPending: isOtpVerifying,
  } = verifyOtpMutation

  const {
    mutate: verifyEmail,
    isSuccess: isEmailVerified,
    isPending: isEmailVerifying,
  } = useMutation({
    mutationFn: (body: IVerifyEmailBody) => verifyEmailAPI(body),
    onSuccess: (data) => {
      const { isVerified, isExpired, phoneNumber, userId, email } = data

      if (!isVerified) {
        setIsEmailVerificationFailed(true)
        setIsTokenExpired(isExpired)
        setEmail(email)
      }

      const formattedPhoneNumber = `${"*".repeat(
        phoneNumber.length - 4,
      )}${phoneNumber.slice(-3)}`
      setFormattedPhoneNumber(formattedPhoneNumber)
      setUserIdFromEmail(userId)
    },
    onError: () => {
      setIsEmailVerificationFailed(true)
    },
  })

  const { mutate: mutateResendEmail } = useMutation({
    mutationFn: async (body: string) => await resendEmailAPI(body),
    onSuccess: () => {
      toast.show(t("emailSent"), "success")
    },
  })
  const { mutate: resendOtp, isPending: isOtpResending } = useMutation({
    mutationFn: (body: string) => resendOtpAPI(body),
    onSuccess: () => {
      toast.show(t("otpResent"), "success")
    },
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      code: "",
    },
  })

  const userId = searchParams.get("userId")?.replace(/ /g, "+")!
  const token = searchParams.get("token")?.replace(/ /g, "+")!

  useEffect(() => {
    verifyEmail({ token, userId })
  }, [])

  const handleFormSubmit = useCallback(
    (data: FieldValues) => {
      verifyOtp({ userId, otp: data.code })
    },
    [userId],
  )

  const handleResendEmail = () => {
    mutateResendEmail(email)
  }

  const handleResendCode = () => {
    resendOtp(userId)
  }

  const handlePhoneNumberUpdateCallback = (newPhoneNumber: string) => {
    const formatted = `${"*".repeat(
      newPhoneNumber.length - 4,
    )}${newPhoneNumber.slice(-3)}`
    setFormattedPhoneNumber(formatted)
  }

  const handlePhoneNumberUpdate = () => {
    setUpdatePhoneModalOpen(true)
  }
  const onContinueToOTP = useCallback(() => setOtpFormVisible(true), [])

  const onBackToLoginClick = useCallback(() => navigate("../login"), [])

  const onBackToRegisterClick = useCallback(() => navigate("../register"), [])

  if (isOtpVerified) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <SuccessCheckIcon />
        <Box marginBottom="16px" />
        <Typography variant="h3" textAlign="center">
          {t("thankYou")}
        </Typography>
        <Box marginBottom="12px" />
        <Typography variant="large" color={colors.black2} textAlign="center">
          {t("signUpSuccessDescription")}
        </Typography>
        <Typography marginTop="16px" color={colors.black2}>
          <Trans
            i18nKey="backToLogin"
            components={[
              <ClickableLabel
                key="1"
                onClick={onBackToLoginClick}
                variant="regularBold"
              />,
            ]}
          />
        </Typography>
      </Box>
    )
  }

  if (isOtpResending) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography marginBottom="24px" variant="h5" color={colors.primary}>
          {t("resendingOtpCode")}
        </Typography>
        <CircularProgress />
      </Box>
    )
  }

  if (isOtpFormVisible) {
    return (
      <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Typography variant="h3">{t("verification")}</Typography>
        <Typography variant="large" color={colors.black2}>
          {t("enterVerificationCode", {
            phoneNumber: formattedPhoneNumber,
          })}
        </Typography>
        <Box marginBottom="8px" />
        <Typography variant="large" color={colors.black2}>
          <Trans
            i18nKey="updatePhoneNumberPrompt"
            components={[
              <ClickableLabel
                key="1"
                onClick={handlePhoneNumberUpdate}
                variant="regularBold"
              />,
            ]}
          />
        </Typography>
        <Box marginBottom="24px" />
        <Typography variant="large" color={colors.black2}>
          {t("enterYourCode")}
        </Typography>
        <Box marginBottom="24px" />
        <Controller
          control={control}
          name="code"
          rules={{
            minLength: {
              value: 6,
              message: t("codeLength"),
            },
            required: {
              value: true,
              message: t("required"),
            },
          }}
          render={({ field: { value, onChange } }) => (
            <CodeInput
              fieldsNumber={6}
              value={value}
              onChange={onChange}
              error={!!errors?.code}
              helperText={errors?.code?.message}
            />
          )}
        />
        <Box marginTop="16px" />
        <ClickableLabel onClick={handleResendCode} variant="regularBold">
          {t("resendCode")}
        </ClickableLabel>
        <Box marginTop="32px" marginBottom="16px">
          <LoadingButton fullWidth type="submit" loading={isOtpVerifying}>
            {t("submit")}
          </LoadingButton>
        </Box>
        <UpdatePhoneNumber
          open={isUpdatePhoneModalOpen}
          userId={userIdFromEmail}
          onClose={() => setUpdatePhoneModalOpen(false)}
          onPhoneNumberUpdate={handlePhoneNumberUpdateCallback}
        />
      </Box>
    )
  }

  if (isEmailVerifying) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography marginBottom="24px" variant="h5" color={colors.primary}>
          {t("verifyingEmail")}
        </Typography>
        <CircularProgress />
      </Box>
    )
  }

  if (isEmailVerificationFailed) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="large">{t("emailVerificationFailed")}</Typography>
        {isTokenExpired ? (
          <>
            <Box marginBottom="12px" />
            <Typography
              marginTop="16px"
              color={colors.black2}
              textAlign="center"
            >
              <Trans
                i18nKey="tokenExpiredResendEmail"
                components={[
                  <ClickableLabel
                    key="1"
                    onClick={handleResendEmail}
                    variant="regularBold"
                  />,
                ]}
              />
            </Typography>
          </>
        ) : (
          <>
            <Box marginBottom="12px" />
            <Typography marginTop="16px" color={colors.black2}>
              <Trans
                i18nKey="backToLoginOrRegister"
                components={[
                  <ClickableLabel
                    key="1"
                    onClick={onBackToRegisterClick}
                    variant="regularBold"
                  />,
                  <ClickableLabel
                    key="2"
                    onClick={onBackToLoginClick}
                    variant="regularBold"
                  />,
                ]}
              />
            </Typography>
          </>
        )}
      </Box>
    )
  }

  if (isEmailVerified) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <SuccessCheckIcon />
        <Box marginBottom="16px" />
        <Typography variant="h3" color={colors.black2} textAlign="center">
          {t("thankYou")}
        </Typography>
        <Box marginBottom="12px" />
        <Typography variant="large" color={colors.gray3} textAlign="center">
          {t("emailVerificationSuccessMessage")}
        </Typography>
        <Box marginBottom="24px" />
        <Button fullWidth onClick={onContinueToOTP}>
          {t("continue")}
        </Button>
      </Box>
    )
  }

  return null
}
