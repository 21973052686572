import { useCallback, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { ProfileButton } from "../../styled"
import {
  colors,
  convertLanguageCodeToName,
  getLanguageCode,
  getLocaleLanguage,
} from "../../../../../../../utils"
import dayjs from "dayjs"
import { updateUserAPI } from "../../../../../../../services"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { SelectedLanguageButton } from "./styled"
import { CircularProgress } from "@mui/material"

export const LanguageSettings = () => {
  const { t } = useTranslation()
  const [isLanguageExpanded, setIsLanguageExpanded] = useState(false)
  const [displayLanguage, setDisplayLanguage] = useState("")
  const queryClient = useQueryClient()

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const { mutate: updateLanguage, isPending: isLanguageUpdating } = useMutation(
    {
      mutationFn: (nextUser: Partial<IUser>) => updateUserAPI(nextUser),
      onSuccess: (user: IUser) => {
        queryClient.setQueryData(["user"], user)
        void queryClient.refetchQueries({ queryKey: ["claim-template"] })
      },
    },
  )

  useEffect(() => {
    if (user) {
      setDisplayLanguage(getLanguageCode(user.userSettings.language))
      dayjs.locale(getLocaleLanguage(user.userSettings.language))
    }
  }, [user])

  const handleLanguageChange = useCallback(
    (language: string) => {
      void updateLanguage({
        lastName: user?.lastName!,
        firstName: user?.firstName!,
        userSettings: {
          isKeepMeLoggedInEnabled: user?.userSettings.isKeepMeLoggedInEnabled!,
          language: convertLanguageCodeToName(language),
        },
      })
    },
    [user, updateLanguage],
  )

  const toggleLanguageMenu = useCallback(() => {
    setIsLanguageExpanded((prevOpen) => !prevOpen)
  }, [])

  return (
    <>
      <ProfileButton
        variant="text"
        onClick={toggleLanguageMenu}
        endIcon={
          isLanguageUpdating ? (
            <CircularProgress size={20} />
          ) : isLanguageExpanded ? (
            <ExpandLessIcon htmlColor={colors.gray9} />
          ) : (
            <ExpandMoreIcon htmlColor={colors.gray9} />
          )
        }
      >
        {t("languageSettings")}
      </ProfileButton>

      {isLanguageExpanded && (
        <>
          {["de", "deInformal", "en"].map((language) => {
            const isSelected = displayLanguage === language
            const ButtonComponent = isSelected
              ? SelectedLanguageButton
              : ProfileButton

            return (
              <ButtonComponent
                key={language}
                variant="text"
                disabled={isLanguageUpdating}
                onClick={() => handleLanguageChange(language)}
                startIcon={
                  <img
                    loading="lazy"
                    width="20"
                    src={
                      language === "en"
                        ? "https://flagcdn.com/w20/gb.png"
                        : "https://flagcdn.com/w20/de.png"
                    }
                    alt=""
                  />
                }
              >
                {t(
                  language === "de"
                    ? "German"
                    : language === "deInformal"
                    ? "InformalGerman"
                    : "english",
                )}
              </ButtonComponent>
            )
          })}
        </>
      )}
    </>
  )
}
