import { Box, Collapse, Grid, Radio, Tooltip, Typography } from "@mui/material"
import { colors, getNestedProperty } from "../../../../utils"
import { ContainerBox } from "./styled"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ErrorIcon from "@mui/icons-material/Error"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { type ISectionItemProps } from "../../types"
import { memo, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Icon from "@mui/material/Icon"
import dayjs from "dayjs"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { SubsectionsList } from "../SubsectionsList"

export const SectionItem = memo((props: ISectionItemProps) => {
  const {
    claim,
    formMethods,
    sectionsWithError,
    section,
    selectedSection,
    isComparisonPreview,
    sectionsCompleted,
    onSectionClick,
  } = props
  const { t } = useTranslation()

  const isExpanded =
    !!isComparisonPreview || selectedSection?.id === section?.id

  const shouldDisable = useMemo(
    () => !!section?.isDocumentSection && !formMethods?.formState?.isValid,
    [section?.isDocumentSection, formMethods?.formState?.isValid],
  )

  const mapNestedValue = useCallback(
    (control: string) => {
      const value = getNestedProperty(claim?.claimDetails, control)
      return value != null
        ? !!value && typeof value === "object"
          ? Object.values(value)
              ?.filter((v) => !!v)
              ?.join(", ")
          : dayjs(value, "YYYY-MM-DD HH:mm:ss").isValid()
          ? dayjs(value).format("DD.MM.YYYY")
          : value
        : null
    },
    [claim?.claimDetails, getNestedProperty],
  )

  const infoItems = useMemo(
    () =>
      section?.info
        ?.map((i) => {
          let value = ""
          if (i?.controlName?.includes(";")) {
            const controls = i.controlName.split(";")
            value = controls?.map((c) => mapNestedValue(c))?.join(" ")
          } else {
            const fieldValue = mapNestedValue(i?.controlName)

            if (i.customValues?.length) {
              const translation = i.customValues.find(
                (v) => v.value === fieldValue,
              )

              if (translation) {
                value = translation.label
              } else {
                value = fieldValue
              }
            } else {
              value = fieldValue
            }
          }
          return { ...i, value }
        })
        ?.filter((i) => !!i?.value?.trim()),
    [section, mapNestedValue],
  )

  const hasError = useMemo(
    () => sectionsWithError?.includes(section.id),
    [sectionsWithError, section],
  )

  const isCompleted = useMemo(
    () => sectionsCompleted?.includes(section.id),
    [sectionsWithError, section],
  )

  return (
    <Tooltip title={shouldDisable ? t("disabledStepMessage") : undefined}>
      <Box id={section.id} component="span">
        <ContainerBox
          expanded={isExpanded}
          preview={!!isComparisonPreview}
          className={shouldDisable ? "pointer-events-none" : ""}
        >
          <Box
            display="flex"
            alignItems="center"
            flex={1}
            borderRadius="8px 8px 0px 0px"
            bgcolor={
              isComparisonPreview
                ? colors.gray8
                : shouldDisable
                ? colors.secondary
                : isExpanded
                ? colors.gray8
                : colors.transparent
            }
            padding="24px"
            className={shouldDisable ? "disabled" : "pointer"}
            onClick={() => onSectionClick?.(section)}
          >
            <Tooltip title={hasError ? t("sectionErrorTooltip") : null}>
              <Box
                component="span"
                marginRight="16px"
                display="flex"
                alignItems="center"
              >
                {((!hasError && !isCompleted) || isExpanded) && (
                  <Radio
                    size="medium"
                    checked={isExpanded}
                    color={
                      isComparisonPreview
                        ? "default"
                        : hasError
                        ? "error"
                        : isCompleted
                        ? "success"
                        : "primary"
                    }
                  />
                )}
                {hasError && !isExpanded && <ErrorIcon color="error" />}
                {isCompleted && !isExpanded && (
                  <CheckCircleIcon color="success" />
                )}
              </Box>
            </Tooltip>

            <Grid container alignItems="center" spacing="24px">
              <Grid item xs={6} sm={4} md={4} lg={3} xl={2.3}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="6px"
                  paddingY="4px"
                >
                  <Typography variant="regularMedium">
                    {section.name}
                  </Typography>
                  {!!section?.description && !isExpanded && (
                    <Tooltip title={section.description}>
                      <InfoOutlinedIcon
                        fontSize="small"
                        htmlColor={colors.gray3}
                      />
                    </Tooltip>
                  )}
                </Box>
              </Grid>
              {!!infoItems?.length && (
                <Grid item xs={6} sm={8} md={8} lg={9} xl={9.7}>
                  <Grid container spacing="12px">
                    {infoItems?.map((i, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={2.4}
                        xl={2.4}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          gap="6px"
                        >
                          <Icon
                            sx={{
                              color: colors.black2,
                            }}
                            fontSize="small"
                          >
                            {i.icon}
                          </Icon>
                          <Typography
                            variant="smallMedium"
                            className="line-clamp-1"
                          >
                            {i.value}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>

          <Collapse
            in={isExpanded}
            timeout="auto"
            // unmountOnExit
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="16px"
              padding="24px"
            >
              {!!section?.description && (
                <Typography variant="small">{section.description}</Typography>
              )}
              {!!section?.subSections?.length && <SubsectionsList {...props} />}
            </Box>
          </Collapse>
        </ContainerBox>
      </Box>
    </Tooltip>
  )
})
