import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  useIsFetching,
  useQuery,
  useQueryClient,
  useMutation,
} from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import {
  AUTHORIZED_CONTENT_MAX_WIDTH,
  colors,
  hasPermissionsToView,
} from "../../utils"
import { DatePicker, TabButton } from "./styled"
import {
  ActivitySummary,
  ClaimNotSynced,
  EditWidgetsPopup,
  OpenActivities,
  WidgetsGrid,
} from "./components"
import { Cached as CachedIcon } from "@mui/icons-material"
import { useAppContext } from "../../contexts"
import { AlertNotification } from "./components/AlertNotification"
import {
  getEditWidgetsAPI,
  getOrganizationsAPI,
  updateWidgetVisibilityAPI,
} from "../../services"
import { GroupSwitchPopup } from "../../components"

export interface IFilters {
  from?: string
  to?: string
}

interface DashboardWidgetVisibility {
  isShowingTotalClaims: boolean
  isShowingOpenClaims: boolean
  isShowingSubmittedClaims: boolean
  isShowingTotalUsers: boolean
  isShowingNewUsers: boolean
  isShowingActivitySummary: boolean
  isShowingOpenActivities: boolean
  isShowingClaimsOutOfSync: boolean
  isShowingMalfunctionsWidget: boolean
  isShowingNewsWidget: boolean
}

const getDefaultVisibleTable = (toggles: DashboardWidgetVisibility | null) => {
  if (!toggles) return "none"
  if (toggles.isShowingOpenActivities) return "open_activities"
  if (toggles.isShowingActivitySummary) return "activity_summary"
  if (toggles.isShowingClaimsOutOfSync) return "claim_out_of_sync"
  return "none"
}

export const DashboardPage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { state: appState } = useAppContext()

  const [filters, setFilters] = useState<IFilters>({})
  const [selectedGroups, setSelectedGroups] = useState<string[]>([
    appState?.groupId!,
  ])
  const [toggles, setToggles] = useState<DashboardWidgetVisibility | null>(null)
  const [closeAllRows, setCloseAllRows] = useState<boolean>(false)

  const { data: widgetVisibility } = useQuery({
    queryKey: ["widgetVisibility", appState.groupId],
    queryFn: () => getEditWidgetsAPI(appState.groupId!),
    refetchOnMount: true,
  })
  const { data: user } = useQuery<IUser>({ queryKey: ["user"] })

  const { data: organizations } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => getOrganizationsAPI(),
  })

  const areStatisticsFetching = !!useIsFetching({ queryKey: ["statistics"] })
  const isActivitySummaryFetching = !!useIsFetching({
    queryKey: ["activitySummary"],
  })
  const areOpenActivitiesFetching = !!useIsFetching({
    queryKey: ["openActivities"],
  })
  const areClaimsOutOfSyncFetching = !!useIsFetching({
    queryKey: ["claimsOutOfSync"],
  })
  const areMalfunctionsFetching = !!useIsFetching({
    queryKey: ["malfunctions"],
  })
  const areNewUsersFetching = !!useIsFetching({ queryKey: ["new-users"] })
  const areLatestNewsFetching = !!useIsFetching({ queryKey: ["latest-news"] })

  const isFetching =
    areStatisticsFetching ||
    isActivitySummaryFetching ||
    areOpenActivitiesFetching ||
    areClaimsOutOfSyncFetching ||
    areNewUsersFetching ||
    areLatestNewsFetching ||
    areMalfunctionsFetching

  useEffect(() => {
    if (widgetVisibility) {
      setToggles(widgetVisibility)
      setVisibleTable(getDefaultVisibleTable(widgetVisibility))
    }
  }, [widgetVisibility])

  const { mutate, isPending } = useMutation({
    mutationFn: (newToggles: DashboardWidgetVisibility) =>
      updateWidgetVisibilityAPI(appState.groupId!, newToggles),
    onSuccess: (updatedToggles) => {
      setToggles(updatedToggles)
      setVisibleTable(getDefaultVisibleTable(updatedToggles))
    },
  })

  const hasPermissionToViewActivityTable = useMemo(
    () => hasPermissionsToView("DASHBOARD_ACTIVITY_SUMMARY_TABLE", user),
    [user],
  )

  const hasPermissionToViewOpenActivitiesTable = useMemo(
    () => hasPermissionsToView("DASHBOARD_OPEN_ACTIVITIES_TABLE", user),
    [user],
  )

  const hasPermissionToViewClaimOutOfSyncTable = useMemo(
    () => hasPermissionsToView("DASHBOARD_CLAIMS_OUT_OF_SYNC_TABLE", user),
    [user],
  )

  const hasPermissionToViewGroupSelection = useMemo(
    () => hasPermissionsToView("DASHBOARD_COMBINE_ALL_GROUPS", user),
    [user],
  )

  const hasPermissionToViewMalfunctionsWidget = useMemo(
    () => hasPermissionsToView("DASHBOARD_MALFUNCTIONS", user),
    [user],
  )

  const hasPermissionToViewSomeTable =
    hasPermissionToViewActivityTable ||
    hasPermissionToViewOpenActivitiesTable ||
    hasPermissionToViewClaimOutOfSyncTable

  const [visibleTable, setVisibleTable] = useState<
    "none" | "activity_summary" | "open_activities" | "claim_out_of_sync"
  >(
    !hasPermissionToViewSomeTable
      ? "none"
      : hasPermissionToViewActivityTable
      ? "activity_summary"
      : hasPermissionToViewOpenActivitiesTable
      ? "open_activities"
      : "claim_out_of_sync",
  )

  const handleGroupUpdate = useCallback(
    (selectedGroups: string[]) => setSelectedGroups(selectedGroups),
    [],
  )

  const onRefreshClick = useCallback(() => {
    setCloseAllRows(true)
    void queryClient.refetchQueries({ queryKey: ["statistics"] })
    void queryClient.refetchQueries({ queryKey: ["malfunctions"] })

    if (visibleTable === "activity_summary") {
      void queryClient.refetchQueries({ queryKey: ["activitySummary"] })
    } else if (visibleTable === "open_activities") {
      void queryClient.refetchQueries({ queryKey: ["openActivities"] })
    } else if (visibleTable === "claim_out_of_sync") {
      void queryClient.refetchQueries({ queryKey: ["claimsOutOfSync"] })
    }
  }, [queryClient, visibleTable])

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (toggles) {
      const updatedToggles = {
        ...toggles,
        [event.target.name]: event.target.checked,
      }
      setToggles(updatedToggles)
      mutate(updatedToggles)
    }
  }

  let typingTimeout: ReturnType<typeof setTimeout>

  const handleUpdateFilters = (key: string, value: any) => {
    clearTimeout(typingTimeout)

    typingTimeout = setTimeout(() => {
      if (dayjs(value).isValid()) {
        setFilters((prev) => ({
          ...prev,
          [key]: dayjs(value).format(),
        }))
      }
      if (!value) {
        setFilters((prev) => ({ ...prev, [key]: "" }))
      }
    }, 500)
  }

  const areAllGroupsSelected = useMemo(
    () =>
      !organizations
        ?.flatMap((o) => o.groups || [])
        ?.some((g) => !selectedGroups?.includes(g?.id)),
    [organizations, selectedGroups],
  )

  const groupsFilter = useMemo(
    () => (areAllGroupsSelected ? [] : selectedGroups),
    [areAllGroupsSelected, selectedGroups],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      padding="24px"
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
      >
        <Box display="flex" flexDirection="row" gap="16px">
          <Box flex={1} display="flex" flexDirection="column">
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              gap="8px"
            >
              <Typography flex={1} variant="h4" className="normal-word-break">
                {t("dashboard")}
              </Typography>
              <EditWidgetsPopup
                toggles={toggles}
                onToggleChange={handleToggleChange}
                isUpdating={isPending}
              />
              <Tooltip title={t("refresh")}>
                <IconButton onClick={onRefreshClick} disabled={isFetching}>
                  <CachedIcon />
                </IconButton>
              </Tooltip>
              {hasPermissionToViewGroupSelection && (
                <GroupSwitchPopup
                  groups={selectedGroups}
                  onUpdate={handleGroupUpdate}
                  showClearFiltersButton
                />
              )}
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="8px"
              >
                <DatePicker
                  label={`${t("date")} ${t("from")}`}
                  format="DD.MM.YYYY"
                  disableFuture
                  value={filters.from ? dayjs(filters.from) : null}
                  slotProps={{ field: { clearable: true } }}
                  onChange={(value) =>
                    handleUpdateFilters("from", value as string)
                  }
                  maxDate={filters.to ? dayjs(filters.to) : dayjs()}
                />
                <DatePicker
                  label={`${t("date")} ${t("to")}`}
                  format="DD.MM.YYYY"
                  value={filters.to ? dayjs(filters.to) : null}
                  slotProps={{ field: { clearable: true } }}
                  onChange={(value) =>
                    handleUpdateFilters("to", value as string)
                  }
                  minDate={filters.from ? dayjs(filters.from) : undefined}
                />
              </Box>
            </Box>

            {toggles?.isShowingMalfunctionsWidget &&
              hasPermissionToViewMalfunctionsWidget && <AlertNotification />}

            <Box marginTop="24px">
              <WidgetsGrid
                widgetVisibility={toggles}
                selectedGroups={groupsFilter}
                filters={filters}
              />
              {hasPermissionToViewSomeTable && (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="8px"
                    marginBottom="16px"
                    marginTop="48px"
                  >
                    {toggles?.isShowingOpenActivities &&
                      hasPermissionToViewOpenActivitiesTable && (
                        <TabButton
                          selected={visibleTable === "open_activities"}
                          onClick={() => setVisibleTable("open_activities")}
                        >
                          {t("openActivities")}
                        </TabButton>
                      )}
                    {toggles?.isShowingActivitySummary &&
                      hasPermissionToViewActivityTable && (
                        <TabButton
                          selected={visibleTable === "activity_summary"}
                          onClick={() => setVisibleTable("activity_summary")}
                        >
                          {t("activitySummary")}
                        </TabButton>
                      )}
                    {toggles?.isShowingClaimsOutOfSync &&
                      hasPermissionToViewClaimOutOfSyncTable && (
                        <TabButton
                          selected={visibleTable === "claim_out_of_sync"}
                          onClick={() => setVisibleTable("claim_out_of_sync")}
                        >
                          {t("claimOutOfSync")}
                        </TabButton>
                      )}
                  </Box>

                  <Box
                    marginY="8px"
                    width="100%"
                    borderTop={`1px solid ${colors.gray4}`}
                  />

                  {visibleTable === "open_activities" &&
                    toggles?.isShowingOpenActivities && (
                      <OpenActivities
                        selectedGroups={groupsFilter}
                        filters={filters}
                      />
                    )}
                  {visibleTable === "activity_summary" &&
                    toggles?.isShowingActivitySummary && (
                      <ActivitySummary
                        filters={filters}
                        selectedGroups={groupsFilter}
                        closeAllRows={closeAllRows}
                      />
                    )}
                  {visibleTable === "claim_out_of_sync" &&
                    toggles?.isShowingClaimsOutOfSync && (
                      <ClaimNotSynced
                        selectedGroups={groupsFilter}
                        filters={filters}
                      />
                    )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
