import React, { useCallback, useEffect, useState } from "react"
import {
  Collapse,
  IconButton,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Box,
} from "@mui/material"
import { Controller, useFieldArray } from "react-hook-form"
import {
  AddCircleOutlined,
  RemoveCircleOutlined,
  KeyboardArrowUp,
  KeyboardArrowDown,
  InfoOutlined,
} from "@mui/icons-material"
import { CustomAutocomplete } from "../../styled"
import { ReactComponent as InvoicePaymentIcon } from "../../../../assets/icons/invoice-payment-icon.svg"
import { ReactComponent as DocumentEditIcon } from "../../../../assets/icons/document-edit-icon.svg"
import { ReactComponent as NotificationIcon } from "../../../../assets/icons/notification-document-icon.svg"
import { ReactComponent as SecureDocumentIcon } from "../../../../assets/icons/secure-document-icon.svg"
import { t } from "i18next"
import { SubsDetails } from "./components"
import { CustomSubTable, CustomTextField, DatePicker } from "./styled"
import { determineFileType } from "../../../../utils"
import { DocPreviewModal } from "../../../DocPreviewModal"

import dayjs from "dayjs"

const getIconForType = (type: string) => {
  switch (type) {
    case "Rehnung":
    case "Zahlung":
      return <InvoicePaymentIcon fontSize="small" />
    case "GeltendGemacht":
    case "NachsteBearbeitung":
      return <DocumentEditIcon fontSize="small" />
    case "Zahlungserinnerung":
    case "Warten":
      return <NotificationIcon fontSize="small" />
    case "Gutschrift":
    case "Nachforderung":
      return <SecureDocumentIcon fontSize="small" />
    default:
      return <InfoOutlined fontSize="small" />
  }
}

interface ISubDetail {
  paymentType: string
  amount: number
}

interface ISub {
  type: string
  sentTo: string
  sentBy: string
  documentNumber: string
  actionDate: string
  subDetails: ISubDetail[]
}

interface SubsTableProps {
  subs: ISub[] | undefined
  isOpen: boolean
  isEditMode: boolean
  control: any
  parentIndex: number
}

export const SubsTable: React.FC<SubsTableProps> = ({
  isOpen,
  isEditMode,
  control,
  parentIndex,
}) => {
  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: `claimCosts.${parentIndex}.subs`,
  })

  const [docPreviewModal, setDocPreviewModal] = useState(false)
  const [expandedSubDetails, setExpandedSubDetails] = useState<number | null>(
    null,
  )

  const toggleSubDetails = useCallback((index: number) => {
    setExpandedSubDetails((prev) => (prev === index ? null : index))
  }, [])
  const [subTypeOptions, setSubTypeOptions] = useState<any[]>([])

  useEffect(() => {
    const options = [
      { label: t("Rehnung"), value: "Rehnung" },
      { label: t("GeltendGemacht"), value: "GeltendGemacht" },
      { label: t("Zahlungserinnerung"), value: "Zahlungserinnerung" },
      { label: t("Zahlung"), value: "Zahlung" },
      { label: t("Gutschrift"), value: "Gutschrift" },
      { label: t("Warten"), value: "Warten" },
      { label: t("Nachforderung"), value: "Nachforderung" },
      { label: t("NachsteBearbeitung"), value: "NachsteBearbeitung" },
    ]
    setSubTypeOptions(options)
  }, [t])

  return (
    <TableRow>
      <TableCell />
      <TableCell style={{ padding: "0px" }} colSpan={6}>
        <Collapse
          style={{ margin: "0px" }}
          in={isOpen}
          timeout="auto"
          unmountOnExit
        >
          <CustomSubTable isEditMode={isEditMode}>
            <TableHead>
              <TableRow>
                <TableCell width="21.25%" align="right" colSpan={1}>
                  <Typography>{t("type")}</Typography>
                </TableCell>
                <TableCell width="21.25%" align="right" colSpan={1}>
                  <Typography>{t("sentTo")}</Typography>
                </TableCell>
                <TableCell width="21.25%" align="right" colSpan={1}>
                  <Typography>{t("sentBy")}</Typography>
                </TableCell>
                <TableCell width="21.25%" align="right" colSpan={1}>
                  <Typography>{t("documentNumber")}</Typography>
                </TableCell>
                <TableCell width="14%" align="right" colSpan={1}>
                  <Typography>{t("date")}</Typography>
                </TableCell>
                {isEditMode && (
                  <TableCell align="right">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        append({
                          type: "",
                          sentTo: "",
                          sentBy: "",
                          documentNumber: "",
                          actionDate: "",
                          subDetails: [],
                        })
                      }
                    >
                      <AddCircleOutlined />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.length === 0 && isEditMode ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    {t("noData")}
                  </TableCell>
                </TableRow>
              ) : (
                fields.map((sub: any, subIndex) => (
                  <React.Fragment key={sub.id}>
                    <TableRow>
                      <TableCell colSpan={1}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          maxWidth="100%"
                          justifyContent="space-between"
                          gap="5px"
                        >
                          <Box display="flex" alignItems="center" gap="5px">
                            <IconButton
                              style={{ padding: "0" }}
                              onClick={() => toggleSubDetails(subIndex)}
                            >
                              {expandedSubDetails === subIndex ? (
                                <KeyboardArrowUp fontSize="small" />
                              ) : (
                                <KeyboardArrowDown fontSize="small" />
                              )}
                            </IconButton>
                            {!isEditMode && (
                              <IconButton
                                onClick={() => setDocPreviewModal(true)}
                                disabled={!sub.fileAttachmentUrl}
                              >
                                {getIconForType(sub.type)}
                              </IconButton>
                            )}
                          </Box>

                          {sub.fileAttachmentUrl && (
                            <DocPreviewModal
                              open={docPreviewModal}
                              file={{
                                name: sub.type ?? "",
                                url: sub.fileAttachmentUrl,
                                id: sub.documentNumber,
                              }}
                              fileType={determineFileType(
                                sub.fileAttachmentUrl,
                              )}
                              key={
                                sub.documentNumber +
                                Math.floor(Math.random() * 100)
                              }
                              onClose={() => setDocPreviewModal(false)}
                            />
                          )}

                          {isEditMode ? (
                            <Controller
                              control={control}
                              name={`claimCosts.${parentIndex}.subs.${subIndex}.type`}
                              defaultValue={sub.type || ""}
                              rules={{ required: t("required") }}
                              render={({ field, formState: { errors } }) => (
                                <CustomAutocomplete
                                  style={{ width: "84%" }}
                                  freeSolo
                                  options={subTypeOptions}
                                  getOptionLabel={(option: any) =>
                                    option.label || ""
                                  }
                                  value={
                                    subTypeOptions.find(
                                      (option) => option.value === field.value,
                                    ) || null
                                  }
                                  onChange={(_, newValue: any) =>
                                    field.onChange(
                                      newValue ? newValue.value : "",
                                    )
                                  }
                                  renderInput={(params) => (
                                    <CustomTextField
                                      {...(params as any)}
                                      InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                      }}
                                      error={
                                        !!(errors as any)?.claimCosts?.[
                                          parentIndex
                                        ]?.subs?.[subIndex]?.type
                                      }
                                      helperText={
                                        (errors as any)?.claimCosts?.[
                                          parentIndex
                                        ]?.subs?.[subIndex]?.type?.message
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          ) : (
                            <Typography textAlign="right" marginLeft="20px">
                              {t(sub.type)}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>

                      <TableCell colSpan={1} align="right">
                        {isEditMode ? (
                          <Controller
                            control={control}
                            name={`claimCosts.${parentIndex}.subs.${subIndex}.sentTo`}
                            render={({ field }) => (
                              <CustomTextField {...field} />
                            )}
                          />
                        ) : (
                          <Typography>{sub.sentTo}</Typography>
                        )}
                      </TableCell>
                      <TableCell colSpan={1} align="right">
                        {isEditMode ? (
                          <Controller
                            control={control}
                            name={`claimCosts.${parentIndex}.subs.${subIndex}.sentBy`}
                            render={({ field }) => (
                              <CustomTextField {...field} />
                            )}
                          />
                        ) : (
                          <Typography>{sub.sentBy}</Typography>
                        )}
                      </TableCell>
                      <TableCell colSpan={1} align="right">
                        {isEditMode ? (
                          <Controller
                            control={control}
                            name={`claimCosts.${parentIndex}.subs.${subIndex}.documentNumber`}
                            render={({ field }) => (
                              <CustomTextField {...field} />
                            )}
                          />
                        ) : (
                          <Typography>{sub.documentNumber}</Typography>
                        )}
                      </TableCell>
                      <TableCell colSpan={1} align="right">
                        {isEditMode ? (
                          <>
                            <Controller
                              control={control}
                              name={`claimCosts.${parentIndex}.subs.${subIndex}.actionDate`}
                              defaultValue={
                                sub.actionDate ? dayjs(sub.actionDate) : dayjs()
                              }
                              rules={{ required: t("required") }}
                              render={({ field, fieldState: { error } }) => (
                                <>
                                  <DatePicker
                                    format="DD.MM.YYYY"
                                    value={
                                      field.value ? dayjs(field.value) : null
                                    }
                                    onChange={(date: any) => {
                                      if (date?.isValid()) {
                                        field.onChange(date.toISOString())
                                      } else {
                                        field.onChange(null)
                                      }
                                    }}
                                    maxDate={dayjs()}
                                    slotProps={{
                                      textField: {
                                        variant: "outlined",
                                        error: !!error,
                                        helperText: error?.message,
                                      },
                                    }}
                                  />
                                </>
                              )}
                            />
                          </>
                        ) : (
                          <Typography>
                            {dayjs(sub.actionDate).format("DD.MM.YYYY")}
                          </Typography>
                        )}
                      </TableCell>
                      {isEditMode && (
                        <TableCell align="right">
                          <IconButton
                            color="error"
                            onClick={() => remove(subIndex)}
                          >
                            <RemoveCircleOutlined />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                    {expandedSubDetails === subIndex && (
                      <SubsDetails
                        isEditMode={isEditMode}
                        control={control}
                        parentIndex={parentIndex}
                        subIndex={subIndex}
                        expandedSubDetails={expandedSubDetails}
                      />
                    )}
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </CustomSubTable>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
