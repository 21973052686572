import {
  Box,
  Typography,
  Grid,
  Skeleton,
  TextField,
  Checkbox,
  FormGroup,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { colors } from "../../../../../../utils"
import {
  getChannelConfigurationsAPI,
  getDocTypesAPI,
} from "../../../../../../services"
import { Search } from "@mui/icons-material"
import { useEffect, useMemo, useState } from "react"
import { ChannelButton } from "./styled"
import { useTranslation } from "react-i18next"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ArticleIcon from "@mui/icons-material/Article"

import FormControlLabel from "@mui/material/FormControlLabel"

interface IProps {
  triggerType: TChannelTypeName
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: any,
    key: string,
    onChange?: (input: any) => void,
  ) => void
  selectedAction: INodeItem
}

const CHANNEL_ID = "ChannelId"
const CHANNEL_TYPE = "ChannelType"
const DOC_TYPE = "DocType"

export const TriggerConfig = (props: IProps) => {
  const { triggerType, handleChange, selectedAction } = props
  const { t } = useTranslation()

  const [search, setSearch] = useState<string>("")
  const [selectAllChannels, setSelectAllChannels] = useState<boolean>(false)
  const [selectAllDocTypes, setSelectAllDocTypes] = useState<boolean>(false)

  const [currentExpanded, setCurrentExpanded] = useState<
    "channels" | "docTypes" | ""
  >("")

  const {
    data: channelConfigurations,
    isPending: isPendingConfigurations,
    isRefetching: isRefetchingConfigurations,
  } = useQuery({
    queryKey: ["channel-configurations"],
    queryFn: () => getChannelConfigurationsAPI(triggerType),
    refetchOnMount: true,
  })

  const {
    data: docTypes,
    isPending: isPendingDocTypes,
    isRefetching: isRefetchingDocTypes,
  } = useQuery({
    queryKey: ["doc-types"],
    queryFn: () => (triggerType === "Email" ? getDocTypesAPI() : []),
    refetchOnMount: true,
  })

  const channels = selectedAction?.inputs?.[CHANNEL_ID] || "''"
  const docs = selectedAction?.inputs?.[DOC_TYPE] || "''"

  useEffect(() => {
    if (channelConfigurations) {
      setSelectAllChannels(
        channelConfigurations
          ?.map((ch) => ch.id)
          .every((id) => channels?.includes(id)),
      )
    }

    if (docTypes) {
      setSelectAllDocTypes(
        docTypes
          ?.map((document) => document.docCategory)
          .every((docCategory) => docs?.includes(docCategory)),
      )
    }

    handleChange(selectedAction, "input", triggerType, CHANNEL_TYPE)
  }, [channelConfigurations, triggerType])

  const onSelectConfigurationHandler = (config: IChannelConfiguration) => {
    let configId: string
    if (channels === undefined || channels === "''") {
      configId = config.id
    } else if (channels.includes(config.id)) {
      if (channels.includes(","))
        configId = `${channels
          .replaceAll("'", "")
          .replace(`,${config.id}`, "")}`
      else
        configId = `${channels.replaceAll("'", "").replace(`${config.id}`, "")}`
      if (configId.length === 0) {
        configId = "''"
      }
    } else {
      configId =
        channels.length > 10
          ? `${channels?.replaceAll("'", "")},${config.id}`
          : config.id
    }

    if (
      channelConfigurations
        ?.map((ch) => ch.id)
        .every((id) => configId.includes(id))
    ) {
      setSelectAllChannels(true)
    } else setSelectAllChannels(false)

    handleChange(selectedAction, "input", configId, CHANNEL_ID)
  }

  const onSelectDocumentHandler = (document: IDocType) => {
    let docType: string
    if (docs === undefined || docs === "''") {
      docType = document.docCategory
    } else if (docs.includes(document.docCategory)) {
      if (docs.includes(",")) {
        docType = `${docs
          .replaceAll("'", "")
          .replace(`,${document.docCategory}`, "")}`
      } else
        docType = `${docs
          .replaceAll("'", "")
          .replace(`${document.docCategory}`, "")}`
      if (docType.length === 0) {
        docType = "''"
      }
    } else {
      docType =
        docs.length > 10
          ? `${docs?.replaceAll("'", "")},${document.docCategory}`
          : document.docCategory
    }

    if (
      docTypes
        ?.map((doc) => doc.docCategory)
        .every((id) => docType.includes(id))
    ) {
      setSelectAllDocTypes(true)
    } else setSelectAllDocTypes(false)

    handleChange(selectedAction, "input", docType, DOC_TYPE)
  }

  const filteredChannels = useMemo(
    () =>
      search
        ? channelConfigurations?.filter(
            (ch) =>
              ch.channelName.toLowerCase()?.includes(search.toLowerCase()),
          )
        : channelConfigurations,
    [channelConfigurations, search],
  )

  const filteredDocTypes = useMemo(
    () =>
      search
        ? docTypes?.filter(
            (ch) =>
              ch.description.toLowerCase()?.includes(search.toLowerCase()),
          )
        : docTypes,
    [docTypes, search],
  )

  const isLoadingChannelConfigs =
    isPendingConfigurations || isRefetchingConfigurations

  const isLoadingDocTypes = isPendingDocTypes || isRefetchingDocTypes

  const handleSelectAllChannels: () => void = () => {
    let allChannels
    if (!selectAllChannels) {
      setSelectAllChannels(true)
      allChannels = channelConfigurations?.map((ch) => ch.id).join(",")
    } else {
      setSelectAllChannels(false)
      allChannels = "''"
    }

    handleChange(selectedAction, "input", allChannels, CHANNEL_ID)
  }

  const handleSelectAllDocTypes: () => void = () => {
    let allDocTypes
    if (!selectAllDocTypes) {
      setSelectAllDocTypes(true)
      allDocTypes = docTypes?.map((doc) => doc.docCategory).join(",")
    } else {
      setSelectAllDocTypes(false)
      allDocTypes = "''"
    }

    handleChange(selectedAction, "input", allDocTypes, DOC_TYPE)
  }

  const numberOfSelectedChannels = `${channelConfigurations?.filter(
    (ch) => channels?.replace(/'/g, "")?.split(",")?.includes(ch.id),
  ).length}/${channelConfigurations?.length}`

  const numberOfSelectedDocTypes = `${docTypes?.filter(
    (document) =>
      docs?.replace(/'/g, "").split(",")?.includes(document.docCategory),
  ).length}/${docTypes?.length}`

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ ml: 1 }} variant="largeMedium">
          {t("step")} {t("id")}: {selectedAction?.id}
        </Typography>
        <TextField
          label={t("search")}
          variant="outlined"
          size="small"
          disabled={isLoadingChannelConfigs}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{ startAdornment: <Search htmlColor={colors.gray2} /> }}
        />
      </Box>

      {isLoadingChannelConfigs ? (
        Array.from({ length: 5 }).map((_, index) => (
          <Box key={index}>
            <Skeleton variant="rounded" height="80px" />
          </Box>
        ))
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            mt: 2,
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Accordion
            expanded={currentExpanded === "channels"}
            sx={{
              width: "100%",
              boxShadow: "none",
              border: `1.5px solid ${
                currentExpanded === "channels" ? colors.primary : colors.gray4
              }`,
              minHeight: "50px",
              borderRadius: "10px !important",
            }}
          >
            <AccordionSummary
              onClick={() =>
                setCurrentExpanded((prev) =>
                  prev !== "channels" ? "channels" : "",
                )
              }
              sx={{
                overflow: "auto",
                height: "50px",
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "10px !important",
                borderBottomLeftRadius:
                  currentExpanded === "channels" ? "0px !important" : "",
                borderBottomRightRadius:
                  currentExpanded === "channels" ? "0px !important" : "",

                alignItems: "center",

                backgroundColor:
                  Number(numberOfSelectedChannels.slice(0, 1)) !== 0
                    ? colors.blue6
                    : currentExpanded === "channels"
                    ? colors.gray6
                    : colors.white,
                mb: currentExpanded === "channels" ? 2 : 0,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ ml: 3, fontSize: 17.5 }}>Channels</Typography>
                {currentExpanded === "channels" && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={(e) => e.stopPropagation()}
                          sx={{ mr: 1 }}
                          checked={selectAllChannels}
                          onChange={handleSelectAllChannels}
                        />
                      }
                      label="Select all"
                    />
                  </FormGroup>
                )}
                {currentExpanded !== "channels" && (
                  <Typography sx={{ mr: 2 }}>
                    Selected: {numberOfSelectedChannels}
                  </Typography>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ maxHeight: "295px", overflow: "auto" }}>
              <Box
                sx={{
                  ml: 1,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {filteredChannels!.length > 0 ? (
                  filteredChannels!.map((ch) => {
                    const isChannelSelected = channels?.includes(ch.id)

                    return (
                      <Box
                        sx={{
                          width: "48%",
                          mb: 1,
                          height: "60px",
                        }}
                        key={ch.id}
                      >
                        <ChannelButton
                          sx={{
                            border: isChannelSelected
                              ? `2px solid ${colors.primary}`
                              : `2px dashed ${colors.primary}`,
                            backgroundColor: isChannelSelected
                              ? colors.blue5
                              : colors.white2,
                          }}
                          isSelected={isChannelSelected}
                          onClick={() => {
                            onSelectConfigurationHandler(ch)
                          }}
                        >
                          <Checkbox
                            sx={{ margin: 0, mr: 2, ml: 3 }}
                            checked={!!isChannelSelected}
                          />
                          <Box sx={{ ml: 1 }}>
                            <Typography
                              variant="regular"
                              className="line-clamp-2"
                              color={colors.black}
                            >
                              {ch.channelName}
                            </Typography>
                            <Typography
                              sx={{ color: colors.gray3, fontWeight: 100 }}
                            >
                              {ch.channelDescription}
                            </Typography>
                          </Box>
                        </ChannelButton>
                      </Box>
                    )
                  })
                ) : (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ArticleIcon sx={{ mr: 1 }} />
                    No channels found
                  </Box>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      {triggerType === "Email" && (
        <Box>
          {isLoadingDocTypes ? (
            Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={6} sm={3} md={2} lg={1.5} key={index}>
                <Box display="flex" flexDirection="column" gap="2px">
                  <Skeleton variant="rounded" height="80px" />
                  <Skeleton variant="text" width="60%" />
                </Box>
              </Grid>
            ))
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                mt: 2,
                flexWrap: "wrap",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <Accordion
                expanded={currentExpanded === "docTypes"}
                sx={{
                  width: "100%",
                  boxShadow: "none",
                  border: `1.5px solid ${
                    currentExpanded === "docTypes"
                      ? colors.primary
                      : colors.gray4
                  }`,
                  minHeight: "50px",
                  borderRadius: "10px !important",
                }}
              >
                <AccordionSummary
                  onClick={() =>
                    setCurrentExpanded((prev) =>
                      prev !== "docTypes" ? "docTypes" : "",
                    )
                  }
                  sx={{
                    overflow: "auto",
                    height: "50px",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "10px !important",
                    borderBottomLeftRadius:
                      currentExpanded === "docTypes" ? "0px !important" : "",
                    borderBottomRightRadius:
                      currentExpanded === "docTypes" ? "0px !important" : "",
                    alignItems: "center",
                    backgroundColor:
                      Number(numberOfSelectedDocTypes.slice(0, 1)) !== 0
                        ? colors.blue6
                        : currentExpanded === "docTypes"
                        ? colors.gray6
                        : colors.white,
                    mb: currentExpanded === "docTypes" ? 2 : 0,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ ml: 3, fontSize: 17.5 }}>
                      Document type
                    </Typography>
                    {currentExpanded === "docTypes" && (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={(e) => e.stopPropagation()}
                              sx={{ mr: 1 }}
                              checked={selectAllDocTypes}
                              onChange={handleSelectAllDocTypes}
                            />
                          }
                          label="Select all"
                        />
                      </FormGroup>
                    )}
                    {currentExpanded !== "docTypes" && (
                      <Typography sx={{ mr: 2 }}>
                        Selected: {numberOfSelectedDocTypes}
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ maxHeight: "295px", overflow: "auto" }}>
                  <Box
                    sx={{
                      ml: 1,
                      display: "flex",
                      flexWrap: "wrap", // Allow items to wrap into multiple rows
                      justifyContent: "space-between", // Space between columns
                    }}
                  >
                    {filteredDocTypes!.length > 0 ? (
                      filteredDocTypes!.map((document, index) => {
                        const docArr = docs.replaceAll("'", "").split([","])
                        const isDocSelected = docArr?.includes(
                          document.docCategory,
                        )
                        return (
                          <Box
                            sx={{
                              width: "48%", // Set width to make two columns
                              mb: 1,
                              height: "60px",
                            }}
                            key={`${document.docCategory}-${index}`}
                          >
                            <Box
                              height="100%"
                              display="flex"
                              flexDirection="column"
                              gap="2px"
                            >
                              <ChannelButton
                                sx={{
                                  border: isDocSelected
                                    ? `2px solid ${colors.primary}`
                                    : `2px dashed ${colors.primary}`,
                                  backgroundColor: isDocSelected
                                    ? colors.blue5
                                    : colors.white2,
                                }}
                                isSelected={isDocSelected}
                                justifyContent="flex-start"
                                alignItems="center"
                                onClick={() =>
                                  onSelectDocumentHandler(document)
                                }
                              >
                                <Checkbox
                                  sx={{ margin: 0, mr: 2, ml: 3 }}
                                  checked={!!isDocSelected}
                                />
                                <Box sx={{ ml: 1 }}>
                                  <Typography
                                    variant="regular"
                                    className="line-clamp-2"
                                    color={colors.black}
                                  >
                                    {document.description}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: colors.gray3,
                                      fontWeight: 100,
                                    }}
                                  >
                                    {document.docCategory}
                                  </Typography>
                                </Box>
                              </ChannelButton>
                            </Box>
                          </Box>
                        )
                      })
                    ) : (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ArticleIcon sx={{ mr: 1 }} />
                        No document types found
                      </Box>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
