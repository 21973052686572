import { Box, IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { colors } from "../../../../utils"
import { useTranslation } from "react-i18next"
import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { LoadingButton } from "@mui/lab"
import { useQuery } from "@tanstack/react-query"

interface IProps {
  claim: IClaimDetails
  disabled: boolean
  isClaimSaving: boolean
  onSubmitClick: () => void
  onSaveAsDraftClick: () => void
}

export const FormFooter = (props: IProps) => {
  const { claim, disabled, isClaimSaving, onSaveAsDraftClick, onSubmitClick } =
    props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const hasPermissionToSubmit = useMemo(() => {
    return user?.permissions?.some(
      (p) => p.action === "SUBMIT_CLAIM" && p.access === "Delete",
    )
  }, [claim])

  const onBackPress = useCallback(() => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1)
    } else {
      navigate("../claims", { replace: true })
    }
  }, [navigate])

  return (
    <Box
      borderTop={`1px solid ${colors.gray13}`}
      display="flex"
      padding="16px"
      gap="16px"
    >
      <Box
        display="flex"
        flexDirection="row"
        flex={1}
        alignItems="center"
        flexWrap="wrap"
        gap="10px"
      >
        <Box marginLeft="-6px">
          <IconButton onClick={onBackPress} color="primary">
            <ArrowBackIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap="8px">
        <LoadingButton
          variant="outlined"
          onClick={onSaveAsDraftClick}
          loading={isClaimSaving}
          disabled={disabled}
        >
          {t("saveAsDraft")}
        </LoadingButton>
        {hasPermissionToSubmit && (
          <LoadingButton onClick={onSubmitClick} disabled={disabled}>
            {t("submit")}
          </LoadingButton>
        )}
      </Box>
    </Box>
  )
}
