import { Skeleton, TablePagination } from "@mui/material"
import { useTranslation } from "react-i18next"

interface IProps {
  count: number
  page: number
  rowsPerPage: number
  isDisabled: boolean
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const CustomPagination = (props: IProps) => {
  const {
    count,
    page,
    rowsPerPage,
    isDisabled,
    onPageChange,
    onRowsPerPageChange,
  } = props
  const { t } = useTranslation()

  const lastPage = Math.ceil(count / rowsPerPage) - 1

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      labelRowsPerPage={t("rowsPerPage")}
      nextIconButtonProps={{
        disabled: isDisabled || page >= lastPage,
      }}
      backIconButtonProps={{
        disabled: isDisabled || page === 0,
      }}
      SelectProps={{ disabled: isDisabled }}
      labelDisplayedRows={({ from, to, count }) =>
        isDisabled || !count ? (
          <Skeleton width="50px" />
        ) : (
          `${from}-${to} ${t("of")} ${count}`
        )
      }
    />
  )
}
