import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Collapse,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { getActivitySummaryAPI } from "../../../../services"
import { useAppContext } from "../../../../contexts"
import type { IFilters } from "../../DashboardPage"
import {
  ClaimDataChanges,
  CostTableChanges,
  LiabilityChanges,
  StatusChanges,
} from "./components"
import dayjs from "dayjs"
import { TabChangesButton } from "../styled"
import { CustomPagination } from "../CustomPagination"
import { TableCellNoPadding } from "../../styled"

export const ActivitySummary = ({
  filters,
  selectedGroups,
  closeAllRows,
}: {
  filters: IFilters
  selectedGroups: string[]
  closeAllRows: boolean
}) => {
  const { t } = useTranslation()
  const { state: appState } = useAppContext()
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [sortBy, setSortBy] = useState("LastChangeDate")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc")
  const [openRow, setOpenRow] = useState<{ rowId: string; tab: string }>()

  const groupIds = selectedGroups || appState?.groupId!

  const {
    data: activitySummary,
    isRefetching,
    isLoading,
  } = useQuery<IDashboardTable>({
    queryKey: [
      "activitySummary",
      filters,
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
      groupIds,
    ],
    queryFn: () =>
      getActivitySummaryAPI({
        groupIds,
        from: filters.from,
        to: filters.to,
        pageNumber: pageNumber + 1,
        pageSize,
        sortBy,
        sortOrder,
      }),
    enabled:
      filters.to && filters.from
        ? dayjs(filters.to).isAfter(filters.from)
        : true,
    refetchOnMount: true,
  })

  useEffect(() => {
    if (closeAllRows) {
      setOpenRow(undefined)
    }
  }, [closeAllRows])

  const handleChangePage = (_: unknown, newPage: number) => {
    setPageNumber(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleSort = (column: string) => {
    setSortBy(column)
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
  }

  const handleRowClick = (rowId: string, tab: string) => {
    setOpenRow({ rowId, tab })
  }

  const handleRowClose = () => {
    setOpenRow(undefined)
  }

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        flexDirection="row"
        gap="8px"
        marginBottom="8px"
      >
        <Typography
          variant="largeMedium"
          className="normal-word-break"
          margin="8px 0"
        >
          {t("activitySummary")}
        </Typography>
      </Box>
      <Box display="grid">
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "ClaimId"}
                    direction={sortOrder}
                    onClick={() => handleSort("ClaimId")}
                  >
                    {t("claimID")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "Rubrum"}
                    direction={sortOrder}
                    onClick={() => handleSort("Rubrum")}
                  >
                    {t("rubrum")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "LastChangeDate"}
                    direction={sortOrder}
                    onClick={() => handleSort("LastChangeDate")}
                  >
                    {t("lastChangedAt")}
                  </TableSortLabel>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isRefetching || isLoading ? (
                <>
                  {[...Array(pageSize)].map((_, index) => (
                    <TableRow key={index}>
                      <TableCell width="15%">
                        <Skeleton />
                      </TableCell>
                      <TableCell width="25%">
                        <Skeleton />
                      </TableCell>
                      <TableCell width="15%">
                        <Skeleton />
                      </TableCell>
                      <TableCell width="45%" align="right">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          gap="32px"
                        >
                          {[...Array(4)].map((_, index) => (
                            <Skeleton
                              key={index}
                              variant="rounded"
                              width="120px"
                            />
                          ))}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                activitySummary?.claims?.map((row: any, index: any) => (
                  <>
                    <TableRow key={index}>
                      <TableCell width="15%">{row.caseNumber}</TableCell>
                      <TableCell width="25%">{row.rubrum}</TableCell>
                      <TableCell width="15%">
                        {dayjs(row.lastChangedAt).format("DD.MM.YYYY hh:mm")}
                      </TableCell>
                      <TableCellNoPadding width="45%" align="right">
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          gap="32px"
                        >
                          <TabChangesButton
                            onClick={() =>
                              handleRowClick(row.claimId, "claimData")
                            }
                            disabled={!row.claimDataChanges}
                            title="claimData"
                            selected={
                              openRow?.tab === "claimData" &&
                              openRow?.rowId === row.claimId
                            }
                          >
                            {t("claimData")}
                          </TabChangesButton>
                          <TabChangesButton
                            onClick={() =>
                              handleRowClick(row.claimId, "costTable")
                            }
                            disabled={!row.costTableChanges}
                            title="costTable"
                            selected={
                              openRow?.tab === "costTable" &&
                              openRow?.rowId === row.claimId
                            }
                          >
                            {t("costTable")}
                          </TabChangesButton>
                          <TabChangesButton
                            onClick={() =>
                              handleRowClick(row.claimId, "status")
                            }
                            disabled={!row.statusChanges}
                            title="status"
                            selected={
                              openRow?.tab === "status" &&
                              openRow?.rowId === row.claimId
                            }
                          >
                            {t("status")}
                          </TabChangesButton>
                          <TabChangesButton
                            onClick={() =>
                              handleRowClick(row.claimId, "liability")
                            }
                            disabled={!row.liabilityChanges}
                            title="liability"
                            selected={
                              openRow?.tab === "liability" &&
                              openRow?.rowId === row.claimId
                            }
                          >
                            {t("liability")}
                          </TabChangesButton>
                        </Box>
                      </TableCellNoPadding>
                    </TableRow>
                    {openRow?.rowId === row?.claimId && (
                      <TableRow>
                        <TableCell
                          style={{
                            padding: 0,
                            borderBottom:
                              openRow?.rowId === row?.claimId
                                ? undefined
                                : "none",
                          }}
                          colSpan={6}
                        >
                          <Collapse
                            in={openRow?.rowId === row?.claimId}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box marginY="24px">
                              <Box
                                display="flex"
                                marginBottom="32px"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Box
                                  display="flex"
                                  gap="8px"
                                  alignItems="center"
                                >
                                  <Typography variant="regularSemiBold">
                                    {openRow?.tab === "status"
                                      ? t("statusChanges")
                                      : openRow?.tab === "costTable"
                                      ? t("costTableChanges")
                                      : openRow?.tab === "liability"
                                      ? t("liabilityChanges")
                                      : t("claimDataChanges")}
                                  </Typography>
                                </Box>
                                <Box display="flex" gap="8px">
                                  <Button onClick={handleRowClose} size="small">
                                    {t("close")}
                                  </Button>
                                </Box>
                              </Box>

                              {openRow?.tab === "claimData" && (
                                <ClaimDataChanges
                                  claimId={row.claimId}
                                  filters={filters}
                                />
                              )}
                              {openRow?.tab === "costTable" && (
                                <CostTableChanges
                                  claimId={row.claimId}
                                  filters={filters}
                                />
                              )}
                              {openRow?.tab === "status" && (
                                <StatusChanges
                                  claimId={row.claimId}
                                  filters={filters}
                                />
                              )}
                              {openRow?.tab === "liability" && (
                                <LiabilityChanges
                                  claimId={row.claimId}
                                  filters={filters}
                                />
                              )}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <CustomPagination
                  count={activitySummary?.totalCount!}
                  page={pageNumber}
                  rowsPerPage={pageSize}
                  isDisabled={isRefetching || isLoading}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
