import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { colors } from "../../utils/colors"

export const UnderDevelopment = () => {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor={colors.white}
      padding="24px"
    >
      <Typography variant="h4" color="textSecondary">
        {t("underDevelopment")}
      </Typography>
    </Box>
  )
}

export default UnderDevelopment
