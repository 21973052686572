import React, { useCallback, useState } from "react"
import {
  TextField,
  CircularProgress,
  type TextFieldProps,
  Typography,
  Box,
} from "@mui/material"
import { useAppContext } from "../../contexts"
import { useQuery } from "@tanstack/react-query"
import { getSearchResultsAPI } from "../../services"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import debounce from "lodash/debounce"
import { CustomSearchIcon, StyledAutocomplete } from "./styled"

interface IProps {
  inputRef?: any
}

const SearchBar = (props: IProps) => {
  const { inputRef } = props
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false)
  const { state: appState } = useAppContext()
  const navigate = useNavigate()

  const {
    data: searchResults,
    isLoading,
    isRefetching,
  } = useQuery<ISearchResults>({
    queryKey: ["global-search", searchQuery],
    queryFn: () =>
      getSearchResultsAPI({
        groupId: appState?.groupId!,
        search: searchQuery,
      }),
    enabled: !!searchQuery,
  })

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 350),
    [],
  )

  const handleInputChange = (_: React.SyntheticEvent, value: string) => {
    debouncedSearch(value)
    setAutocompleteOpen(!!value)
  }

  const handleSelect = (
    _: React.SyntheticEvent,
    value: IClaimSearchData | null,
  ) => {
    if (value) {
      navigate(`/claims/${value.id}`)
      setAutocompleteOpen(false)
    }
  }

  const filteredOptions = (searchResults?.claims.claims || []).filter(
    (option) => option?.advoWareId !== null,
  )

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && filteredOptions.length === 1) {
      handleSelect(event, filteredOptions[0])
    }
  }

  return (
    <>
      <StyledAutocomplete
        id="claim-search"
        options={filteredOptions}
        getOptionLabel={(option: any) => option?.advoWareId}
        filterOptions={(options) => options}
        loading={isLoading || isRefetching}
        onInputChange={handleInputChange}
        onChange={(event, value) =>
          handleSelect(event, value as IClaimSearchData)
        }
        onKeyDown={handleKeyPress}
        open={autocompleteOpen}
        renderInput={(params) => (
          <TextField
            {...(params as TextFieldProps)}
            inputRef={inputRef}
            variant="outlined"
            placeholder={t("search")}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: <CustomSearchIcon />,
              endAdornment: (
                <>
                  {isLoading || isRefetching ? (
                    <CircularProgress size={20} />
                  ) : null}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option: any) => (
          <li {...props} key={option.advoWareId}>
            <Box display="flex" flexDirection="column">
              <Typography variant="regularMedium">
                {option.advoWareId}
              </Typography>
              {option.rubrum && (
                <Typography variant="small">
                  <b>{t("rubrum")}:</b> {option.rubrum}
                </Typography>
              )}
              {option.officialRegistrationNumber && (
                <Typography variant="small">
                  <b>{t("officialRegistrationNumber")}:</b>{" "}
                  {option.officialRegistrationNumber}
                </Typography>
              )}
              {option.accidentDate && (
                <Typography variant="small">
                  <b>{t("accidentDate")}:</b> {option.accidentDate}
                </Typography>
              )}
            </Box>
          </li>
        )}
      />
    </>
  )
}

export default SearchBar
