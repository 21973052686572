import { Button, Select, styled } from "@mui/material"
import { colors } from "../../../../../../utils"

export const RemoveButton = styled(Button)`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${colors.red};
  padding: 0px !important;
  min-width: 36px;
  width: 36px;
  height: 36px;
`

export const CustomSelect = styled(Select)(() => ({
  "&.Mui-disabled": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.gray2,
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.gray2,
      },
    },
  },
}))
