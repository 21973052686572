import { Table, TableContainer, TableRow, styled } from "@mui/material"
import { colors } from "../../../../utils"

export const EnhancedTableContainer = styled(TableContainer)({
  borderTop: 0,
  borderBottom: 0,
  padding: "0px 24px",
  overflowY: "initial",
})

export const EnhancedTable = styled(Table)(() => ({
  borderCollapse: "separate",

  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      height: "auto",
    },
  },

  "& > .MuiTableFooter-root": {
    "& .MuiTableCell-root": {
      backgroundColor: colors.secondary,
      border: `1px solid ${colors.gray13}`,
      borderRadius: "10px",
    },
  },
}))

export const EmptyRow = styled(TableRow)({
  height: "16px",

  "& .MuiTableCell-root": {
    padding: "0px !important",
    borderBottom: "0px !important",
    borderTop: "0px !important",
  },
})

export const NoDataRow = styled(TableRow)({
  "& .MuiTableCell-root": {
    padding: "16x !important",
    borderBottom: "0px !important",
    borderTop: "0px !important",
    paddingBottom: "24px !important",
  },
})

interface IRowProps {
  open: boolean
  disabled?: boolean
}

export const CustomRow = styled(TableRow)<IRowProps>(({ open, disabled }) => ({
  borderRadius: "10px",
  transition: "all 0.2s ease-in-out",

  "& .MuiTableCell-root": {
    padding: "0px !important",

    transition: "all 0.2s ease-in-out",
    borderTop: `1px solid ${open ? colors.primary : colors.gray10}`,
    borderBottom: `1px solid ${colors.gray10} !important`,
    "&:first-of-type": {
      borderLeft: `1px solid ${open ? colors.primary : colors.gray10}`,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: open ? "0px" : "10px",
      paddingLeft: "6px !important",
    },
    "&:last-of-type": {
      borderRight: `1px solid ${open ? colors.primary : colors.gray10}`,
      borderLeft: "0px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: open ? "0px" : "10px",
    },

    "& .MuiTypography-root": {
      lineHeight: "20px",
    },
  },

  "&:hover": {
    "&.MuiTableRow-root": {
      transform: open || disabled ? "none" : "scale(1.01)",
    },
    "& .MuiTableCell-root": {
      cursor: disabled ? "auto" : "pointer",
      borderTop: `1px solid ${
        open ? colors.primary : disabled ? colors.gray10 : colors.primary
      }`,
      borderBottom: `1px solid ${
        open || disabled ? colors.gray10 : colors.primary
      } !important`,

      "&:first-of-type": {
        borderLeft: `1px solid ${
          open ? colors.primary : disabled ? colors.gray10 : colors.primary
        }`,
      },
      "&:last-of-type": {
        borderRight: `1px solid ${
          open ? colors.primary : disabled ? colors.gray10 : colors.primary
        }`,
      },
    },
  },
}))

export const CollapsibleRow = styled(TableRow)<IRowProps>(({ open }) => ({
  "& > .MuiTableCell-root": {
    borderBottom: open
      ? `1px solid ${colors.primary} !important`
      : "none !important",
    borderRight: `1px solid ${colors.primary}`,
    borderLeft: `1px solid ${colors.primary}`,
    borderTop: "none",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",

    padding: "0px !important",

    "& .MuiCollapse-root": {
      margin: "16px",
    },
  },
}))

export const CustomRowHeader = styled(TableRow)({
  backgroundColor: colors.secondary,
  borderRadius: "0px !important",
  borderBottom: `2px solid ${colors.white}`,

  "& .MuiTableCell-root": {
    backgroundColor: colors.secondary + " !important",
    borderTop: `1px solid ${colors.gray13}`,
    borderBottom: `1px solid ${colors.gray13}`,

    "&:first-of-type": {
      borderLeft: `1px solid ${colors.gray13}`,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-of-type": {
      borderRight: `1px solid ${colors.gray13}`,
      borderLeft: "0px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
})
