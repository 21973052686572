import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { getClaimAPI } from "../../../../services"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "../../../../contexts"
import { colors, determineFileType } from "../../../../utils"
import DocViewer, {
  DocViewerRenderers,
  type IDocument,
} from "@cyntler/react-doc-viewer"
import { useMemo, useState } from "react"
import { TableContainerStyled, TableRowStyled } from "./styled"

interface IProps {
  claimId: string
}

export const DocumentsOverview = (props: IProps) => {
  const { claimId } = props
  const { t } = useTranslation()
  const { state: appState } = useAppContext()

  const {
    data: claim,
    isLoading: isClaimDetailsLoading,
    isRefetching: isClaimDetailsRefetching,
    isError: getClaimDetailsFailed,
  } = useQuery({
    queryKey: ["claim-details", claimId],
    queryFn: () =>
      getClaimAPI({ id: claimId, params: { groupId: appState?.groupId! } }),
    refetchOnMount: "always",
  })

  const [selectedFileIndex, setSelectedFileIndex] = useState(0)

  const files = claim?.claimDocuments?.map((d) => d.files)?.flat()

  const selectedFile = useMemo(
    () => files?.[selectedFileIndex],
    [files, selectedFileIndex],
  )

  const documents: IDocument[] | undefined = useMemo(() => {
    if (!selectedFile) {
      return undefined
    }

    return [
      {
        fileName: selectedFile.name,
        fileType: determineFileType(selectedFile.name),
        uri: selectedFile.url,
      },
    ]
  }, [selectedFile])

  const config = useMemo(
    () => ({
      header: {
        disableHeader: true,
      },
      pdfVerticalScrollByDefault: true,
    }),
    [],
  )

  const isDataLoading = isClaimDetailsLoading || isClaimDetailsRefetching

  return (
    <Box display="flex" flexDirection="column" bgcolor={colors.white}>
      {isDataLoading && (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
          paddingBottom="24px"
        >
          <CircularProgress />
        </Box>
      )}
      {getClaimDetailsFailed && (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{t("failedToLoad")}</Typography>
        </Box>
      )}
      {!isDataLoading && !files?.length && (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{t("noDocumentsAlert")}</Typography>
        </Box>
      )}
      {!isDataLoading && !!files?.length && (
        <Box display="flex" flexDirection="row" gap="24px" width="100%">
          <Box display="grid" flex={1}>
            <TableContainerStyled>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("name")}</TableCell>
                    <TableCell>{t("type")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files?.map((file, index) => (
                    <TableRowStyled
                      key={file.id}
                      onClick={() => setSelectedFileIndex(index)}
                      selected={index === selectedFileIndex}
                    >
                      <TableCell>{file.name}</TableCell>
                      <TableCell>{determineFileType(file.name)}</TableCell>
                    </TableRowStyled>
                  ))}
                </TableBody>
              </Table>
            </TableContainerStyled>
          </Box>
          {!!documents?.[0]?.uri && (
            <Box
              border={`1px solid ${colors.gray11}`}
              padding="16px"
              bgcolor={colors.white}
              width="530px"
              height="750px"
              display="block"
            >
              <DocViewer
                key={documents[0].uri}
                documents={documents}
                pluginRenderers={DocViewerRenderers}
                config={config}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
