import { TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import { type IFieldItemControllerProps } from "../../../../types"
import { formatIban } from "../../../../../../utils"
import { memo } from "react"

export const BaseController = memo((props: IFieldItemControllerProps) => {
  const {
    control,
    field,
    subsection,
    section,
    label,
    rules,
    isDisabled,
    onFieldFocus,
    onFieldBlur,
  } = props

  return (
    <Controller
      control={control}
      name={field.controlName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <TextField
          fullWidth
          multiline={field.fieldType === "TextArea"}
          rows={4}
          value={value ?? ""}
          type={
            field.fieldType === "IBAN"
              ? "text"
              : field.fieldType?.toString()?.toLowerCase()
          }
          onFocus={() => {
            onFieldFocus?.(section.sectionType)
          }}
          onChange={(e) => {
            let nextValue = e.target.value

            if (field.rules?.isCapsLocked) {
              nextValue = nextValue.toUpperCase()
            }

            if (!nextValue) {
              onChange(null)
            } else if (field.fieldType === "Number") {
              const nextValueParsed = Number(nextValue)
              if (
                typeof nextValueParsed === "number" &&
                !isNaN(nextValueParsed)
              ) {
                onChange(nextValueParsed)
              } else {
                onChange(null)
              }
            } else if (field.fieldType === "IBAN") {
              onChange(formatIban(nextValue))
            } else {
              onChange(nextValue)
            }
          }}
          onBlur={() => {
            onBlur()
            onFieldBlur?.(
              { controlName: field.controlName, value },
              subsection,
              section,
            )
          }}
          label={label}
          disabled={isDisabled}
          error={!!error?.message}
          helperText={error?.message}
        />
      )}
    />
  )
})
