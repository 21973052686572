import { type MouseEventHandler, useCallback, useMemo, useState } from "react"
import { Box, Collapse, Tooltip, Typography } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck"
import { ExpandIconButton, GroupBox, GroupLogo } from "./styled"
import { colors, findGroups } from "../../utils"
import { useTranslation } from "react-i18next"

interface IProps {
  group: IGroup
  multiselect?: boolean
  isTopParent?: boolean
  selectedGroupIds?: string[]
  hasSelectedParent?: boolean
  onChangeGroup: (group: IGroup) => void
}

export const GroupLineItem = (props: IProps) => {
  const {
    group,
    multiselect,
    selectedGroupIds,
    isTopParent,
    hasSelectedParent,
    onChangeGroup,
  } = props
  const { t } = useTranslation()
  const [isExpanded, setExpanded] = useState(false)
  const isSelected = !!selectedGroupIds?.includes(group?.id)
  const hasSubgroups = !!group?.subGroups?.length

  const handleExpand: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation()
      setExpanded((prev) => !prev)
    },
    [],
  )

  const hasActiveSubgroup = useMemo(() => {
    if (!isExpanded && hasSubgroups && selectedGroupIds?.length) {
      return !!findGroups(group.subGroups, selectedGroupIds)?.length
    }
    return false
  }, [isExpanded, hasSubgroups, selectedGroupIds])

  const isDisabled = useMemo(
    () =>
      !!multiselect &&
      (hasSelectedParent || !!selectedGroupIds?.includes(group.parentGroupId)),
    [group, multiselect, hasSelectedParent, selectedGroupIds],
  )

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" width="100%">
        {!isTopParent && (
          <Box borderTop={`0.5px dashed ${colors.gray2}`} width="7px" />
        )}
        <ExpandIconButton onClick={handleExpand} disabled={!hasSubgroups}>
          {isExpanded ? (
            <ExpandMoreIcon fontSize="large" />
          ) : (
            <ChevronRightIcon fontSize="large" />
          )}
        </ExpandIconButton>
        <GroupBox
          className={isDisabled ? "disabled" : ""}
          selected={isSelected}
          onClick={() => {
            onChangeGroup(group)
          }}
        >
          {hasActiveSubgroup && (
            <Tooltip title={t("hasActiveGroupTooltip")}>
              <Box
                position="absolute"
                top="-8px"
                right="-8px"
                width="28px"
                height="28px"
                display="flex"
                justifyContent="center"
                border={`1px solid ${colors.primary}`}
                alignItems="center"
                borderRadius="99px"
                bgcolor={colors.white}
              >
                <PlaylistAddCheckIcon fontSize="small" color="primary" />
              </Box>
            </Tooltip>
          )}

          <GroupLogo variant="square" src={group?.logo}>
            {group?.name
              ?.split(" ")
              ?.slice(0, 2)
              ?.map((word) => word?.[0])}
          </GroupLogo>
          <Typography flex={1} variant="regularMedium" className="line-clamp-2">
            {group.name}
          </Typography>
          {isSelected && <CheckIcon color="primary" />}
        </GroupBox>
      </Box>
      {hasSubgroups && (
        <Box display="flex" flexDirection="row">
          <Box
            borderLeft={`1px dashed ${colors.gray2}`}
            height="auto"
            display="flex"
            marginLeft={isTopParent ? "14px" : "21px"}
          />
          <Box flex={1}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              {group.subGroups
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((subgroup) => (
                  <GroupLineItem
                    key={subgroup.id}
                    group={subgroup}
                    selectedGroupIds={selectedGroupIds}
                    hasSelectedParent={isDisabled}
                    multiselect={multiselect}
                    onChangeGroup={onChangeGroup}
                  />
                ))}
            </Collapse>
          </Box>
        </Box>
      )}
    </Box>
  )
}
