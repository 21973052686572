import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { useForm, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { updatePhoneNumberAPI } from "../../../../services"
import { useToast } from "../../../../contexts"
import { PhoneNumberInput } from "../../../../components"
import { useCallback } from "react"
import { isValidPhoneNumber } from "react-phone-number-input"
import { LoadingButton } from "@mui/lab"

interface UpdatePhoneNumberProps {
  open: boolean
  userId: string
  onClose: () => void
  onPhoneNumberUpdate: (phoneNumber: string) => void
}

export const UpdatePhoneNumber = ({
  open,
  onClose,
  userId,
  onPhoneNumberUpdate,
}: UpdatePhoneNumberProps) => {
  const { t } = useTranslation()
  const toast = useToast()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { phoneNumber: "" },
  })

  const { mutate: updatePhoneNumber, isPending: isUpdating } = useMutation({
    mutationFn: (payload: IUpdatePhoneParams) => updatePhoneNumberAPI(payload),
    onSuccess: () => {
      toast.show(t("updatedPhoneNumber"), "success")
      reset()
      onClose()
    },
    onError: () => {
      toast.show(t("failedToUpdatePhoneNumber"), "error")
    },
  })

  const validatePhoneNumber = useCallback((value: string) => {
    if (!isValidPhoneNumber(value)) {
      return t("invalidPhoneNumberErrorMessage")
    } else {
      return true
    }
  }, [])

  const handleUpdateClick = (data: { phoneNumber: string }) => {
    updatePhoneNumber({ userId, phoneNumber: data.phoneNumber })
    onPhoneNumberUpdate(data.phoneNumber)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("updatePhoneNumber")}</DialogTitle>
      <DialogContent>
        <Box marginBottom="12px" />
        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            required: t("required"),
            validate: validatePhoneNumber,
          }}
          render={({ field: { value, onChange } }) => (
            <PhoneNumberInput
              fullWidth
              value={value}
              onChange={onChange}
              label={t("mobilePhone")}
              error={!!errors?.phoneNumber}
              helperText={errors?.phoneNumber?.message}
              disabled={isUpdating}
              data-cy="phone-number"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t("cancel")}
        </Button>
        <LoadingButton
          onClick={handleSubmit(handleUpdateClick)}
          color="primary"
          disabled={isUpdating}
          loading={isUpdating}
        >
          {t("update")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
