import { Alert, type AlertColor, Snackbar } from "@mui/material"
import React, { createContext, useCallback, useContext, useState } from "react"

interface IToastContextValue {
  show: (message: string, severity?: AlertColor) => void
  hide: () => void
}

const ToastContext = createContext<IToastContextValue>(undefined!)

interface IProps {
  children?: React.ReactNode
}

export const useToast = () => useContext(ToastContext)

export const ToastProvider = (props: IProps) => {
  const { children } = props
  const [isVisible, setVisible] = useState(false)
  const [message, setMessage] = useState("")
  const [severity, setSeverity] = useState<AlertColor | undefined>()

  const show = useCallback((message: string, severity?: AlertColor) => {
    setMessage(message ?? "")
    setSeverity(severity ?? "error")
    setVisible(true)
  }, [])

  const hide = useCallback(() => {
    setMessage("")
    setSeverity(undefined)
    setVisible(false)
  }, [])

  const handleClose = useCallback(() => {
    setVisible(false)
  }, [])

  const value = {
    show,
    hide,
  }

  return (
    <ToastContext.Provider value={value}>
      <Snackbar
        open={isVisible}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
      {children}
    </ToastContext.Provider>
  )
}
