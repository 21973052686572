import { useState } from "react"
import { Box, Button, Typography, CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { getClaimActivitySummaryAPI } from "../../../../services"

import {
  ClaimDataChanges,
  CostTableChanges,
  LiabilityChanges,
  StatusChanges,
} from "../../../DashboardPage/components/ActivitySummary/components"
import { TabChangesButton } from "../../../DashboardPage/components/styled"

export const ClaimActivities = ({ claimId }: { claimId: string }) => {
  const { t } = useTranslation()
  const [openRow, setOpenRow] = useState<{ rowId: string; tab: string }>()

  const {
    data: claimActivities,
    isRefetching,
    isLoading,
  } = useQuery<IDashboardTableData>({
    queryKey: ["claimActivities", claimId],
    queryFn: () => getClaimActivitySummaryAPI(claimId),
    refetchOnMount: true,
  })

  const handleRowClick = (rowId: string, tab: string) => {
    setOpenRow({ rowId, tab })
  }

  const handleRowClose = () => {
    setOpenRow(undefined)
  }

  const isDataLoading = isLoading || isRefetching
  return (
    <>
      <Box display="flex" flexDirection="column" gap="24px">
        <Box display="flex" gap="8px">
          {isDataLoading ? (
            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="center"
              paddingBottom="24px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TabChangesButton
                onClick={() => handleRowClick(claimId, "claimData")}
                disabled={!claimActivities?.claimDataChanges}
                selected={
                  openRow?.tab === "claimData" && openRow?.rowId === claimId
                }
              >
                {t("claimData")}
              </TabChangesButton>
              <TabChangesButton
                onClick={() => handleRowClick(claimId, "costTable")}
                disabled={!claimActivities?.costTableChanges}
                selected={
                  openRow?.tab === "costTable" && openRow?.rowId === claimId
                }
              >
                {t("costTable")}
              </TabChangesButton>
              <TabChangesButton
                onClick={() => handleRowClick(claimId, "status")}
                disabled={!claimActivities?.statusChanges}
                selected={
                  openRow?.tab === "status" && openRow?.rowId === claimId
                }
              >
                {t("status")}
              </TabChangesButton>
              <TabChangesButton
                onClick={() => handleRowClick(claimId, "liability")}
                disabled={!claimActivities?.liabilityChanges}
                selected={
                  openRow?.tab === "liability" && openRow?.rowId === claimId
                }
              >
                {t("liability")}
              </TabChangesButton>
            </>
          )}
        </Box>

        {openRow?.rowId === claimId && (
          <Box>
            <Box
              display="flex"
              marginBottom="32px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="regularSemiBold">
                {openRow?.tab === "status"
                  ? t("statusChanges")
                  : openRow?.tab === "costTable"
                  ? t("costTableChanges")
                  : openRow?.tab === "liability"
                  ? t("liabilityChanges")
                  : t("claimDataChanges")}
              </Typography>

              <Button onClick={handleRowClose} size="small">
                {t("close")}
              </Button>
            </Box>

            {openRow?.tab === "claimData" && (
              <ClaimDataChanges claimId={claimId} />
            )}
            {openRow?.tab === "costTable" && (
              <CostTableChanges claimId={claimId} />
            )}
            {openRow?.tab === "status" && <StatusChanges claimId={claimId} />}
            {openRow?.tab === "liability" && (
              <LiabilityChanges claimId={claimId} />
            )}
          </Box>
        )}
      </Box>
    </>
  )
}
