import { Box, styled } from "@mui/material"
import { colors } from "../../../../utils"
import { LoadingButton } from "@mui/lab"

export const ComponentWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
`

export const Container = styled(Box)`
  width: 100%;
  height: min-content;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  padding-top: 36px;
`

export const TitleBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const SectionBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const DeleteButton = styled(LoadingButton)`
  width: fit-content;
  color: ${colors.red} !important;
  border-color: ${colors.red} !important;
  background-color: ${colors.white2} !important;
`

export const InformationItem = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`
