import * as React from "react"
import { styled } from "@mui/material/styles"
import Tooltip, {
  type TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { ExpandMore } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"
import dayjs from "dayjs"

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

interface IProps {
  rowDetails: IGlobalInboxItemDetail
  emailDetails: IGlobalInboxItemEmail
}

export const EmailDetailsTooltip = (props: IProps) => {
  const { rowDetails, emailDetails } = props

  const { t } = useTranslation()

  return (
    <LightTooltip
      placement="bottom-start"
      title={
        <Box display="flex" flexDirection="column" gap="8px" padding="12px">
          <Typography variant="small" color="gray">
            {t("from")}:{" "}
            <Typography variant="small">{rowDetails.sender ?? "-"} </Typography>
          </Typography>
          <Typography variant="small" color="gray">
            {t("to")}:{" "}
            <Typography variant="small">
              {rowDetails.recipient ?? "-"}{" "}
            </Typography>
          </Typography>
          <Typography variant="small" color="gray">
            {t("dateAndTime")}:{" "}
            {emailDetails.createdAt ? (
              <Typography variant="small">
                {dayjs(emailDetails.createdAt).format("DD.MM.YYYY HH:mm:ss")}{" "}
              </Typography>
            ) : (
              <Typography variant="small">-</Typography>
            )}
          </Typography>
          <Typography variant="small" color="gray">
            {t("subject")}:{" "}
            <Typography variant="small">{rowDetails.subject ?? "-"}</Typography>
          </Typography>
        </Box>
      }
    >
      <Typography
        variant="small"
        display="flex"
        alignItems="center"
        marginBottom="8px"
        width="-webkit-fit-content"
      >
        {t("to")}:{" "}
        {rowDetails.recipient ? rowDetails.recipient.split("@")[0] : "-"}{" "}
        <ExpandMore fontSize="inherit" />
      </Typography>
    </LightTooltip>
  )
}
