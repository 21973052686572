import { useEffect } from "react"
import { Box, Typography, CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { colors } from "../../utils"
import { verifyInvitationAPI } from "../../services"
import { useAppContext, useToast } from "../../contexts"

export const InvitationVerificationPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const toast = useToast()
  const queryClient = useQueryClient()
  const {
    state: { authorization },
  } = useAppContext()

  const isAuthorized = authorization === "authorized"
  const invitationId = searchParams.get("invitationId")?.replace(/ /g, "+")

  const { mutate: verifyInvitation, isPending: isInvitationVerifying } =
    useMutation({
      mutationFn: (invitationId: string) => verifyInvitationAPI(invitationId),
      onSuccess: (data) => {
        const now = Date.now()
        const expiresOn = new Date(data.expiresOn).getTime()

        if (
          expiresOn < now ||
          (data.status === "Expired" && !data.successfullyAddedToGroup)
        ) {
          toast.show(t("invitationExpired"), "error")
          navigate("/")
          return
        }

        const messageKey = data.userExists
          ? data.successfullyAddedToGroup
            ? "userExistsAndAddedToGroup"
            : data.status === "Accepted"
            ? "userAlreadyInGroup"
            : "userExistsButNotAddedToGroup"
          : null

        if (messageKey) {
          toast.show(
            t(messageKey),
            data.successfullyAddedToGroup || data.status === "Accepted"
              ? "success"
              : "error",
          )
          if (data.successfullyAddedToGroup && isAuthorized) {
            void queryClient.refetchQueries({ queryKey: ["organizations"] })
          }
          navigate("/")
        } else {
          navigate("/register", {
            state: { invitationId, email: data.userEmail },
          })
        }
      },
      onError: () => {
        navigate("/")
      },
    })

  useEffect(() => {
    if (invitationId) {
      verifyInvitation(invitationId)
    }
  }, [invitationId])

  if (isInvitationVerifying) {
    return (
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        mt={isAuthorized ? 4 : 0}
      >
        <CircularProgress size={50} />
        <Box marginBottom="24px" />
        <Typography
          variant="extraLarge"
          color={colors.black2}
          textAlign="center"
        >
          {t("isInvitationVerifying")}
        </Typography>
      </Box>
    )
  }

  return null
}
