import React, { useEffect, useMemo, useState } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  FormControlLabel,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import { Controller, useForm } from "react-hook-form"
import { useToast } from "../../contexts"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createGroupAPI } from "../../services"
import {
  CITY_REGEX_VALIDATION,
  NUMERIC_REGEX_VALIDATION,
  URL_REGEX,
} from "../../utils"
import { CustomSelect } from "./styled"
import { StyledCheckbox } from "../UpdateGroupModal/styled"

interface CreateGroupModalProps {
  open: boolean
  selectedOrgId?: string
  selectedGroup?: IGroup
  organizations: Array<{ id: string; name: string }>
  onClose: () => void
}

const CreateGroupModal: React.FC<CreateGroupModalProps> = ({
  open,
  selectedOrgId = "",
  selectedGroup,
  organizations,
  onClose,
}) => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [isOverridingGroupSettings, setIsOverridingGroupSettings] =
    useState(false)

  const isSubgroup = !!selectedGroup

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const hasPermissionForOverridingSettings = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "OVERWRITE_GROUP_SETTINGS" && p.access === "Delete",
      ),
    [user],
  )

  const addGroupToNestedStructure = (
    groups: IGroup[],
    parentGroupId: string,
    newGroup: IGroup,
  ): IGroup[] => {
    return groups.map((group) => {
      if (group.id === parentGroupId) {
        return {
          ...group,
          subGroups: [...(group.subGroups || []), newGroup],
        }
      } else if (group.subGroups?.length) {
        return {
          ...group,
          subGroups: addGroupToNestedStructure(
            group.subGroups,
            parentGroupId,
            newGroup,
          ),
        }
      }
      return group
    })
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ICreateGroupBody>({
    defaultValues: {
      name: "",
      organizationId: "",
      isEnabled: true,
      externalDetails: {
        addressNr: undefined,
        referat: undefined,
        lawyer: "",
        contactPersonFullName: "",
        contactPersonId: undefined,
      },
      isOverridingGroupSettings: false,
      city: null,
      baseUrl: null,
    },
  })

  useEffect(() => {
    if (open) {
      reset({
        name: "",
        organizationId: isSubgroup
          ? selectedGroup?.organizationId
          : selectedOrgId || "",
        isEnabled: true,
        externalDetails: {
          addressNr: undefined,
          referat: undefined,
          lawyer: "",
          contactPersonFullName: "",
          contactPersonId: undefined,
        },
        isOverridingGroupSettings: false,
        city: null,
        baseUrl: null,
      })
    }
  }, [open, selectedOrgId, reset])

  const { mutate: createGroup, isPending: isCreating } = useMutation({
    mutationFn: (data: ICreateGroupBody) => createGroupAPI(data),
    onSuccess: (newGroup, variables) => {
      toast.show(t("groupCreated"), "success")

      queryClient.setQueryData<IOrganization[]>(
        ["organizations-disabled"],
        (old) =>
          old?.map((org) => {
            if (org.id === variables.organizationId) {
              return {
                ...org,
                groups: selectedGroup?.id
                  ? addGroupToNestedStructure(
                      org.groups,
                      selectedGroup.id,
                      newGroup,
                    )
                  : [...org.groups, newGroup],
              }
            }
            return org
          }),
      )

      void queryClient.refetchQueries({ queryKey: ["organizations"] })
      onClose()
    },
  })

  const onFormSubmit = (data: ICreateGroupBody) => {
    let baseUrl = data.baseUrl
    if (baseUrl?.endsWith("/")) {
      baseUrl = baseUrl.slice(0, -1)
    }

    const payload: ICreateGroupBody = {
      ...data,
      city: data.city === "" ? null : data.city,
      baseUrl: baseUrl === "" ? null : baseUrl,
      parentGroupId: selectedGroup?.id || null,
    }

    const filteredPayload: ICreateGroupBody = {
      ...payload,
      externalDetails: isOverridingGroupSettings
        ? {
            ...payload.externalDetails,
            contactPersonFullName:
              payload.externalDetails.contactPersonFullName || null,
            contactPersonId: payload.externalDetails.contactPersonId || null,
          }
        : (null as any),
    }

    createGroup(filteredPayload)
  }

  return (
    <Dialog
      component="form"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <DialogTitle>{t("createGroup")}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="16px" margin="10px">
          <Controller
            name="organizationId"
            control={control}
            rules={{ required: t("required") }}
            render={({ field }) => (
              <FormControl fullWidth disabled={isSubgroup}>
                <InputLabel>{t("selectOrganization")}</InputLabel>
                <CustomSelect {...field} label={t("selectOrganization")}>
                  {organizations.map((org) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            )}
          />
          {isSubgroup && !!selectedGroup?.name && (
            <TextField
              label={t("parentGroupName")}
              value={selectedGroup.name}
              disabled
              fullWidth
            />
          )}
          <Controller
            name="name"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("groupName")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />

          <Controller
            name="city"
            control={control}
            rules={{
              pattern: {
                value: CITY_REGEX_VALIDATION,
                message: t("onlyLettersErrorMessage"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("city")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="baseUrl"
            control={control}
            rules={{
              pattern: {
                value: URL_REGEX,
                message: t("invalidUrl"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("baseUrl")}
                placeholder="https://"
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          {hasPermissionForOverridingSettings && (
            <Controller
              name="isOverridingGroupSettings"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={isOverridingGroupSettings}
                      onChange={(e) => {
                        field.onChange(e.target.checked)
                        setIsOverridingGroupSettings(e.target.checked)
                      }}
                    />
                  }
                  label={t("overwriteOrganizationSettings")}
                  labelPlacement="end"
                />
              )}
            />
          )}
          {isOverridingGroupSettings && (
            <>
              <Controller
                name="externalDetails.addressNr"
                control={control}
                rules={{
                  required: {
                    value: isOverridingGroupSettings,
                    message: t("required"),
                  },
                  min: {
                    value: 0,
                    message: t("minNumberErrorMessage", {
                      value: 0,
                    }),
                  },
                  pattern: {
                    value: NUMERIC_REGEX_VALIDATION,
                    message: t("onlyDigitsErrorMessage"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("addressNr")}
                    error={!!error?.message}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="externalDetails.referat"
                control={control}
                rules={{
                  required: {
                    value: isOverridingGroupSettings,
                    message: t("required"),
                  },
                  min: {
                    value: 0,
                    message: t("minNumberErrorMessage", {
                      value: 0,
                    }),
                  },
                  pattern: {
                    value: NUMERIC_REGEX_VALIDATION,
                    message: t("onlyDigitsErrorMessage"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("referat")}
                    error={!!error?.message}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="externalDetails.lawyer"
                control={control}
                rules={{
                  required: isOverridingGroupSettings && t("required"),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={t("lawyer")}
                    error={!!error?.message}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />

              <Controller
                name="externalDetails.contactPersonFullName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={t("contactPersonFullName")}
                    error={!!error?.message}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="externalDetails.contactPersonId"
                control={control}
                rules={{
                  min: {
                    value: 0,
                    message: t("minNumberErrorMessage", {
                      value: 0,
                    }),
                  },
                  pattern: {
                    value: NUMERIC_REGEX_VALIDATION,
                    message: t("onlyDigitsErrorMessage"),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("contactPersonId")}
                    error={!!error?.message}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton type="submit" loading={isSubmitting || isCreating}>
          {t("create")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateGroupModal
