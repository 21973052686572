import { Box, Popper, styled } from "@mui/material"
import { colors } from "../../../../../../../../../../../../utils"

interface IPopperOptionProps {
  isSelected: boolean
}

export const ElevatedPopper = styled(Popper)`
  z-index: 250;
`

export const PopperOption = styled(Box)<IPopperOptionProps>`
  gap: 24px;
  display: flex;
  cursor: pointer;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ isSelected }) =>
    isSelected ? colors.blue6 : colors.white};

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? "" : colors.blue6)};
  }
`
