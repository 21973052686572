import {
  styled,
  TableContainer,
  TableRow,
  Table,
  TableCell as TCell,
} from "@mui/material"
import { colors } from "../../../../../../utils"

export const EnhancedTableContainer = styled(TableContainer)({
  borderTop: 0,
  borderBottom: 0,
  overflowY: "initial",
  backgroundColor: "inherit",
  marginTop: 10,
  marginBottom: 10,
})

export const EnhancedTable = styled(Table)(() => ({
  borderCollapse: "separate",

  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      height: "auto",
    },
  },

  "& > .MuiTableFooter-root": {
    "& .MuiTableCell-root": {
      backgroundColor: colors.secondary,
      border: `1px solid ${colors.gray13}`,
      borderRadius: "10px",
    },
  },
}))

export const CustomRowHeader = styled(TableRow)({
  height: 50,
  backgroundColor: colors.secondary,
  borderRadius: "0px !important",
  borderBottom: `2px solid ${colors.white}`,

  "& .MuiTableCell-root": {
    padding: "8px 0 !important",
    backgroundColor: colors.secondary + " !important",
    borderTop: `1px solid ${colors.gray13}`,
    borderBottom: `1px solid ${colors.gray13}`,

    "&:first-of-type": {
      borderLeft: `1px solid ${colors.gray13}`,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      paddingLeft: "8px !important",
    },
    "&:last-of-type": {
      borderRight: `1px solid ${colors.gray13}`,
      borderLeft: "0px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
})

export const EmptyRow = styled(TableRow)({
  height: "16px",

  "& .MuiTableCell-root": {
    padding: "0px !important",
    borderBottom: "0px !important",
    borderTop: "0px !important",
  },
})

export const CustomRow = styled(TableRow)(() => ({
  borderRadius: "10px",
  transition: "all 0.2s ease-in-out",

  "& .MuiTableCell-root": {
    padding: "0px !important",
    height: "44px",

    transition: "all 0.2s ease-in-out",
    borderTop: `1px solid ${colors.gray10}`,
    borderBottom: `1px solid ${colors.gray10} !important`,
    "&:first-of-type": {
      borderLeft: `1px solid ${colors.gray10}`,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      paddingLeft: "12px !important",
    },
    "&:last-of-type": {
      borderRight: `1px solid ${colors.gray10}`,
      borderLeft: "0px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
      paddingRight: "12px !important",
    },

    "& .MuiTypography-root": {
      lineHeight: "20px",
    },
  },
}))

export const NoDataRow = styled(TableRow)({
  "& .MuiTableCell-root": {
    padding: "16x !important",
    borderBottom: "0px !important",
    borderTop: "0px !important",
    paddingBottom: "24px !important",
  },
})

interface ITableCellProps {
  minWidth?: string
}

export const TableCell = styled(TCell)<ITableCellProps>`
  min-width: ${(props) => props.minWidth};
`
