import {
  type Control,
  Controller,
  type FieldValues,
  type RegisterOptions,
} from "react-hook-form"
import { PhoneNumberInput } from "../../../../../../components"
import { memo } from "react"

interface IProps {
  formControl: Control<FieldValues, any>
  field: IClaimTemplateMetadata
  disabled: boolean
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
  >
  onFieldBlur?: (field: IChangedField) => void
  onFieldFocus?: () => void
}

export const PhoneNumberController = memo((props: IProps) => {
  const { formControl, field, disabled, rules, onFieldBlur, onFieldFocus } =
    props

  return (
    <Controller
      control={formControl}
      name={field.controlName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <PhoneNumberInput
          value={value}
          onChange={onChange}
          onBlur={() => {
            onBlur()
            onFieldBlur?.({
              controlName: field.controlName,
              value,
            })
          }}
          onFocus={onFieldFocus}
          label={field.label}
          disabled={disabled}
          error={!!error?.message}
          helperText={error?.message}
        />
      )}
    />
  )
})
