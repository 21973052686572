import React, { useEffect, useState, useCallback, useMemo, useRef } from "react"
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"
import {
  getOrganizationsAPI,
  deleteGroupAPI,
  updateAllowedGroupsAPI,
} from "../../services"
import {
  AUTHORIZED_CONTENT_MAX_WIDTH,
  canViewGroupDetails,
  colors,
  findGroup,
  recursiveFilterGroups,
  updateGroupInNestedStructure,
} from "../../utils"
import { LoadingButton } from "@mui/lab"

import { AlertDialog } from "../../components/AlertDialog"
import { useAppContext, useToast } from "../../contexts"
import CreateGroupModal from "../../components/CreateGroupModal/CreateGroupModal"
import UpdateGroupModal from "../../components/UpdateGroupModal/UpdateGroupModal"
import { StyledSelect } from "./styled"
import { Add } from "@mui/icons-material"
import { useLocation } from "react-router-dom"
import { GroupBodyRow, GroupHeadRow } from "./components"
import { GroupSelectDialog } from "../../components"

export const GroupManagementPage: React.FC = () => {
  const { t } = useTranslation()
  const { state: appState } = useAppContext()
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })
  const organizationsQueryParams = {
    includeDisabledGroups: true,
    groupManagementTab: true,
    ownedGroups: true,
  }
  const { breakpoints } = useTheme()
  const isSmallerThanLg = useMediaQuery(breakpoints.down("lg"))
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedOrg, setSelectedOrg] = useState("")
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState<IGroup | undefined>()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [sortBy, setSortBy] = useState("name")
  const [sortOrder, setSortOrder] = useState<TOrder>("asc")
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const organizationIdFromQuery = queryParams.get("organizationId")

  const [allowedGroupsSelectedGroup, setAllowedGroupsSelectedGroup] = useState<
    IGroup | undefined
  >()

  const [isUpdateGroupDialogVisible, setUpdateGroupDialogVisible] =
    useState(false)

  useEffect(() => {
    if (organizationIdFromQuery) {
      setSelectedOrg(organizationIdFromQuery)
    }
  }, [organizationIdFromQuery])

  const toast = useToast()
  const queryClient = useQueryClient()

  const {
    data: organizations,
    isRefetching,
    isLoading,
  } = useQuery<IOrganization[]>({
    queryKey: ["organizations-disabled"],
    queryFn: () => getOrganizationsAPI(organizationsQueryParams),
    refetchOnMount: true,
  })

  const sortedOrganizationsAlphabetically = useMemo(() => {
    return organizations?.sort((a, b) => a.name.localeCompare(b.name))
  }, [organizations])

  const isInitialMount = useRef(true)

  useEffect(() => {
    if (
      isInitialMount.current &&
      organizations?.length &&
      appState?.groupId &&
      !organizationIdFromQuery
    ) {
      const foundOrg = organizations.find(
        (org) => !!findGroup(org.groups, appState.groupId!),
      )
      if (foundOrg) {
        setSelectedOrg(foundOrg.id)
      }
      isInitialMount.current = false
    }
  }, [organizations, appState.groupId])

  const handleChangePage = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage)
    },
    [],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    },
    [],
  )

  const filteredAndSortedGroups = useMemo(() => {
    if (!organizations) return []

    const filteredOrgs = organizations.filter((org) =>
      selectedOrg ? org.id === selectedOrg : true,
    )

    const groups = filteredOrgs.flatMap((org) => org.groups)

    return [...groups].sort((a, b) => {
      const orderMultiplier = sortOrder === "asc" ? 1 : -1
      if (sortBy === "name") {
        return a.name.localeCompare(b.name) * orderMultiplier
      } else if (sortBy === "createdDate") {
        return (
          (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()) *
          orderMultiplier
        )
      }
      return 0
    })
  }, [organizations, selectedOrg, sortBy, sortOrder])

  const visibleRows = useMemo(
    () =>
      filteredAndSortedGroups?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [filteredAndSortedGroups, page, rowsPerPage],
  )

  const totalGroupsCount = useMemo(
    () => filteredAndSortedGroups.length,
    [filteredAndSortedGroups],
  )

  useEffect(() => {
    if (
      !isUpdateGroupDialogVisible &&
      !createModalOpen &&
      !deleteDialogOpen &&
      selectedGroup
    ) {
      setSelectedGroup(undefined)
    }
  }, [isUpdateGroupDialogVisible, createModalOpen, deleteDialogOpen])

  const handleSort = (column: string) => {
    setSortBy(column)
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
  }

  const handleOrgChange = (event: any) => {
    setSelectedOrg(event.target.value as string)
    setPage(0)
    setRowsPerPage(10)
  }

  const onEditClick = (group: IGroup) => {
    setSelectedGroup(group)
    setUpdateGroupDialogVisible(true)
  }

  const onCreateGroupClick = (group?: IGroup) => {
    setSelectedGroup(group)
    setCreateModalOpen(true)
  }

  const { mutate: deleteGroup, isPending: isDeleting } = useMutation({
    mutationFn: (groupId: string) => deleteGroupAPI(groupId),
    onSuccess: () => {
      queryClient.setQueryData<IOrganization[]>(
        ["organizations-disabled"],
        (old) =>
          old?.map((org) =>
            org.id === selectedGroup?.organizationId
              ? {
                  ...org,
                  groups: recursiveFilterGroups(org.groups, [selectedGroup.id]),
                }
              : org,
          ),
      )

      queryClient.setQueryData<IOrganization[]>(
        ["organizations"],
        (old) =>
          old?.map((org) =>
            org.id === selectedGroup?.organizationId
              ? {
                  ...org,
                  groups: recursiveFilterGroups(org.groups, [selectedGroup.id]),
                }
              : org,
          ),
      )

      void queryClient.refetchQueries({
        queryKey: ["organizations-disabled-managed", organizationsQueryParams],
      })

      setDeleteDialogOpen(false)
      toast.show(t("groupDeleted"), "success")
    },
    onError: () => {
      setDeleteDialogOpen(false)
    },
  })

  const { mutate: updateAllowedGroups, isPending: areAllowedGroupsUpdating } =
    useMutation({
      mutationFn: (requestParams: IUpdateAllowedGroupsRequestParams) =>
        updateAllowedGroupsAPI(requestParams),
      onSuccess: (updatedGroup: IGroup) => {
        toast.show(t("groupUpdated"), "success")

        queryClient.setQueryData<IOrganization[]>(
          ["organizations-disabled"],
          (oldData) => {
            return oldData?.map((organization) => {
              return {
                ...organization,
                groups: updateGroupInNestedStructure(
                  organization.groups,
                  updatedGroup?.id,
                  updatedGroup,
                ),
              }
            })
          },
        )

        void queryClient.refetchQueries({ queryKey: ["organizations"] })
        void queryClient.refetchQueries({
          queryKey: [
            "organizations-disabled-managed",
            organizationsQueryParams,
          ],
        })

        setAllowedGroupsSelectedGroup(undefined)
      },
      onSettled: () => {
        setAllowedGroupsSelectedGroup(undefined)
      },
    })

  const onDeleteClick = (group: IGroup) => {
    setSelectedGroup(group)
    setDeleteDialogOpen(true)
  }

  const handleConfirmDelete = () => {
    deleteGroup(selectedGroup?.id!)
  }

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
  }

  const onAllowedGroupsClick = (group: IGroup) =>
    setAllowedGroupsSelectedGroup(group)

  const onSubmitAllowedGroups = (groups: string[]) => {
    const areAllGroupsSelected = !organizations
      ?.flatMap((o) => o.groups)
      ?.some(
        (g) =>
          !groups?.includes(g.id) && g.id !== allowedGroupsSelectedGroup?.id,
      )

    updateAllowedGroups({
      groupId: allowedGroupsSelectedGroup?.id!,
      body: {
        groupId: allowedGroupsSelectedGroup?.id!,
        allowedGroupIds: areAllGroupsSelected ? [] : groups,
        hasAccessToAllGroups: areAllGroupsSelected,
      },
    })
  }

  const closeAllowedGroups = () => setAllowedGroupsSelectedGroup(undefined)

  const hasPermissionToChangeAllowedGroups = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "ALLOWED_GROUPS" && p.access === "Delete",
      ),
    [user],
  )

  const isSuperAdmin = useMemo(() => canViewGroupDetails(user), [user])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      padding="24px"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        gap="8px"
      >
        {!!hasPermissionToChangeAllowedGroups && (
          <GroupSelectDialog
            isVisible={!!allowedGroupsSelectedGroup}
            title={t("allowedGroupsDialogTitle", {
              groupName: allowedGroupsSelectedGroup?.name ?? "",
            })}
            groups={allowedGroupsSelectedGroup?.allowedGroups || []}
            selectAll={allowedGroupsSelectedGroup?.hasAccessToAllGroups}
            hideGroups={
              allowedGroupsSelectedGroup
                ? [allowedGroupsSelectedGroup.id]
                : undefined
            }
            isSubmitButtonLoading={areAllowedGroupsUpdating}
            multiselect
            onSubmit={onSubmitAllowedGroups}
            onClose={closeAllowedGroups}
          />
        )}
        <Box
          display="flex"
          gap={isSmallerThanLg ? "8px" : "16px"}
          flexDirection={isSmallerThanLg ? "column" : "row"}
          alignItems={isSmallerThanLg ? "" : "center"}
          marginBottom="24px"
        >
          <Typography flex={1} variant="h4" paddingRight="16px">
            {t("groupManagement")}
          </Typography>

          <FormControl variant="outlined">
            <InputLabel>{t("selectOrganization")}</InputLabel>

            <StyledSelect
              fullWidth={isSmallerThanLg}
              value={selectedOrg}
              onChange={handleOrgChange}
              label={t("selectOrganization")}
            >
              <MenuItem value="">{t("allOrganizations")}</MenuItem>
              {sortedOrganizationsAlphabetically?.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>

          {isSuperAdmin && (
            <LoadingButton
              onClick={() => setCreateModalOpen(true)}
              startIcon={<Add />}
            >
              {t("createGroup")}
            </LoadingButton>
          )}
        </Box>
        <Box display="grid">
          <TableContainer>
            <Table>
              <TableHead>
                <GroupHeadRow
                  orderBy={sortBy}
                  order={sortOrder}
                  onSortClick={handleSort}
                />
              </TableHead>
              <TableBody>
                {isLoading || isRefetching ? (
                  <>
                    {[...Array(rowsPerPage)].map((_, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {[...Array(4)].map((_, cellIndex) => (
                          <TableCell key={cellIndex}>
                            <Skeleton />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </>
                ) : (
                  visibleRows?.map((group) => (
                    <GroupBodyRow
                      key={group.id}
                      group={group}
                      hasPermissionToChangeAllowedGroups={
                        hasPermissionToChangeAllowedGroups
                      }
                      onAddSubGroupClick={onCreateGroupClick}
                      onEditClick={onEditClick}
                      onDeleteClick={onDeleteClick}
                      onAllowedGroupsClick={onAllowedGroupsClick}
                    />
                  ))
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {isLoading || isRefetching ? (
                    <TableCell colSpan={4}>
                      <Skeleton />
                    </TableCell>
                  ) : (
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50]}
                      count={totalGroupsCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={t("rowsPerPage")}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <CreateGroupModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false)
        }}
        selectedOrgId={selectedOrg}
        selectedGroup={selectedGroup}
        organizations={organizations || []}
      />
      <UpdateGroupModal
        isVisible={isUpdateGroupDialogVisible}
        onClose={() => {
          setUpdateGroupDialogVisible(false)
        }}
        groupData={selectedGroup}
      />
      <AlertDialog
        isVisible={deleteDialogOpen}
        message={t("deleteGroupAlertMessage", {
          groupName: selectedGroup?.name,
        })}
        confirmLabel={t("delete")}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        loading={isDeleting}
      />
    </Box>
  )
}
